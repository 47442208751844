import React, { useState, useEffect, useContext } from "react";
import OneColumn from "../../layout/containers/OneColumn";
import { Grid, Row, Col } from "react-flexbox-grid";
import ExpedientsSearcher from "../expedient_searcher/ExpedientsSearcher";
import { Table } from "../../helpers_components/remote_pagination/Table";
import { columns, getExpedientsList, getSearchOfExpedients } from "./expedients_list_logic";
import LoaderContext from "../../layout/shared/loader_context";

const pageConfig = {
  active: "my_expedients",
  title: "Mis expedientes"
};

const ExpedientsList = (props) => {
  const [cleaning, setCleaning] = useState(false);
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const loader = useContext(LoaderContext);

  useEffect(() => {
    (async function () {
      await getExpedientsList(
        sizePerPage,
        page,
        setData,
        setTotal
      );
    })();
  }, []);

  const searchExpedients = async () => {
    if ( search.length > 0 && /^$|.*\S+.*/.test(search) ) {
      setSearching(true);
      loader.show(true);
      let searchValue = search.replace(/^\s+|\s+$/gm,'').replace('/','-');
      await getSearchOfExpedients(
        searchValue,
        sizePerPage,
        page,
        setData,
        setTotal
      );

      setSearching(false);
      loader.show(false);
    } else {
      cleanSearch()
    }
  };

  const cleanSearch = async () => {
    setSearch("");
    setCleaning(true);
    loader.show(true);
    await getExpedientsList(
      sizePerPage,
      page,
      setData,
      setTotal
    );

    setCleaning(false);
    loader.show(false);
  };

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setSizePerPage(sizePerPage);
    setPage(page);

    await getExpedientsList(
      sizePerPage,
      page,
      setData,
      setTotal
    );
  };

  return (
    <OneColumn {...props} pageConfig={pageConfig}>
      <Grid style={{ minHeight: "62vh" }}>
        <Row>
          <Col xs={12} sm={12}>
            <h4 className="mB-10 mT-30 w-100 text-center">Mis expedientes</h4>
          </Col>
        </Row>
        <Row>
          <ExpedientsSearcher
            placeholderSearch="Escribe el número de expediente"
            placeholderSearchButton="Buscar"
            placeholderCleanButton="Limpiar"
            helperContextual="Escribe el número de expediente y haz clic en el botón buscar."
            executeSearch={searchExpedients}
            cleanSearch={cleanSearch}
            isSearching={searching}
            isCleaning={cleaning}
            setSearch={setSearch}
            search={search}
            classNameButtonsSearch={"buttonsSearchExpedientsList"}
            hasRulesToSearch={true}
            rulesToSearch={/^[a-zA-Z0-9/]*$/}
          />
        </Row>

        <Row>
          <Grid fluid={true}>
            <section className="procedures-table-container mT-30">

              <Table
                data={data}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={total}
                onTableChange={handleTableChange}
                columns={columns()}
              />

            </section>
          </Grid>
        </Row>
      </Grid>
    </OneColumn>
  );
};

export default ExpedientsList;
