import React, {useContext,useEffect} from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import ProcessList from "./ProcessList";
import OneColumn from "../layout/containers/OneColumn";
import notificationContext from '../alert_notification_table/notification_context';
import { printUserNotification } from "../alert_notification_table/alert_notification_logic";


const Formalities = props => {
  let pageConfig = {
    active: "my_formalities",
    title: "Tramites"
  };

  let notificationCtxt = useContext(notificationContext);
  useEffect(() => {
    if( notificationCtxt.count > 0 ){
      printUserNotification();
    }
  }, []);

  return (
    <OneColumn {...props} pageConfig={pageConfig}>
      <Grid className="process_list" style={{ minHeight: "62vh" }}>
        <Row>
          <Col xs={12} sm={12}>
            <h4 className="mB-20 mT-30 w-100 text-center">Conoce más de los trámites en línea</h4>
            <ProcessList props={props} />
          </Col>
        </Row>
      </Grid>
    </OneColumn>
  );
};

export default Formalities;
