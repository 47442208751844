import { NewClassRequest } from "../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";

export const initForm = {
  email: ""
};
export const initFormRecovery = {
  password: "",
  password_confirmation: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
// ###REFACTORIZADA
export const forgotPassword = async (form, props) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/password`,
    "post",
    null,
    {
      email: form.email,
      app_id: 2
    }
  );

  const validateEmail = await request.executeRequest();

  if (validateEmail.code !== 200) {

    NotificationManager.error( validateEmail.response.message );
    return;
  }
  else if ( validateEmail.code === 200 ) {

    if ( validateEmail.response.error ) {

      NotificationManager.error( validateEmail.response.message );
      return;
    }
    else if ( 
      typeof validateEmail.response.message === "string" &&
      validateEmail.response.message !== ""
    ) {

      setTimeout(() => {

        NotificationManager.success(validateEmail.response.message);
      }, 500);
  
      props.history.push("/home");
    }
  }
};

/***********************************************************************************************************************************************/

// ###REFACTORIZADA
export const recoveryPassword = async (form, reset_password_token, props) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/password`,
    "put",
    null,
    {
      password: form.password,
      password_confirmation: form.password_confirmation,
      reset_password_token: reset_password_token
    }
  );
  const validateRecoveryPassword = await request.executeRequest();

  if ( validateRecoveryPassword.code !== 200 ) {

    NotificationManager.error( validateRecoveryPassword.response.message !== "" ? validateRecoveryPassword.response.message : "Ha ocurrido un error revisa la solicitud" );
    return;
  }
  else if ( validateRecoveryPassword.code === 200 ) {

    if (
      validateRecoveryPassword.response.error &&
      validateRecoveryPassword.response.message && 
      typeof  validateRecoveryPassword.response.message === "string" &&
      validateRecoveryPassword.response.message !== ""  
    ) {

      NotificationManager.error( validateRecoveryPassword.response.message );
    }
    else if (
      !validateRecoveryPassword.response.error &&
      validateRecoveryPassword.response.message && 
      typeof  validateRecoveryPassword.response.message === "string" &&
      validateRecoveryPassword.response.message !== "" 
    ) {

      setTimeout(() => {
        NotificationManager.success(
          validateRecoveryPassword.response.message
        );
      }, 500);

      props.history.push("/");
    }
  }

};

/*************************************************************************************************************************************************/
