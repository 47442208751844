import React from "react";
import Cookies from "js-cookie";
import CheckboxReadAlert from "./CheckboxAlert";
import { Request } from "../classes/Request";
import { NotificationManager } from "react-notifications";

export const columns = [
  {
    dataField: "id",
    text: "Id",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "4rem", width: "10%", textAlign: "center" }
  },
  {
    dataField: "procedure_id",
    text: "Trámite",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "12rem", width: "18%", textAlign: "center" }
  },
  {
    dataField: "reason",
    text: "Notificación",
    sort: true,
    headerStyle: { minWidth: "18rem", width: "40%", textAlign: "center" }
  },
  {
    dataField: "created_at",
    text: "Fecha de creación",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "16rem", width: "20%", textAlign: "center" }
  },
  {
    dataField: "read",
    text: "Marcar como leído",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { minWidth: "12rem", width: "20%", textAlign: "center" }
  }
];

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getAllAlertNotifications = async ( userIdLogin ) => {
  let { userId_02 } = Cookies.get();
  userId_02 = userIdLogin ? userIdLogin : userId_02;

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/unread_notifications_online/${userId_02}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getNotificationData = await request.executeRequest();

  if (getNotificationData.code !== 200) {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    return [];
  }

  return getNotificationData.data.data.data.notifications;
};
/**********************************************************FORGOT PASSWORD************************************************************************/
export const updateAlertReadStatus = async (props, read, alertContext) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/notifications/${props.notificationId}`,
    "put",
    null,
    { token: process.env.REACT_APP_TOKEN, read: read }
  );
  const updateAlertData = await request.executeRequest();

  if (updateAlertData.code !== 200) {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    return;
  }

  props.updateReadValue(!props.actualReadValue);
  //THIS LINE MAKE THE BADGE COUNT ALERT RENDER
  alertContext.updateCount(!alertContext.requireUpdate, "subtract");

  //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
  if (updateAlertData.data.data.data.message) {
      return NotificationManager.info(
        updateAlertData.data.data.data.message
      );
  }
};
/******************************************************************************************************************************************************/

/***********************************************************************************************************************************************/
//FORMAT TABLE WITH THE JSX CHECKBOX AND CORRECT HTML
export const formatDataTable = async (data, updateRead, updateReadCheck) => {
  //updateRead: FUNCTION THAH UPDATE THE CHECKBOX WITHOUT RELOADING THE PAGE
  //updateReadCheck: ACTUAL VALUE OF THE FLAG, FOR CHECKING UPDATE VALUE OF CHECKBOX
  let newArrayFormated = data;
  newArrayFormated.forEach(el => {
    if (!el.read) {
      el.read = (
        <CheckboxReadAlert
          notificationId={el.id}
          updateReadValue={updateRead}
          actualReadValue={updateReadCheck}
        />
      );
    }
  });
  return newArrayFormated;
};

/***********************************************************************************************************************************************/

export const printUserNotification = async () => {
  
  return NotificationManager.warning(
    "Tienes notificaciones pendientes por leer",
    "Estimado usuario: ",
    1800
  );
};