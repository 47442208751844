import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";
import { Col, Row } from "react-flexbox-grid";
import es from "date-fns/locale/es";

// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";
registerLocale("es", es);


const DynamicDatePicker = props => {
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    if (props.state[props.el.name]) {
      setStartDate(new Date(props.state[props.el.name]));
    }
  }, []);

  const onChangeEventHandler = async date => {
    setStartDate(date);
    props.onChangeHandler(date, props.el.name, props.el.type);
  };

  const disableFieldBasedMethod = el => {
    const { userId_02 } = Cookies.get();
    if (!el.readonly) {
      if (props.mode === 2) {
        return true;
      }
      if (props.mode === 1) {
        return false;
      } else {
        if (parseInt(userId_02) !== parseInt(props.applicant)) {
          return true;
        } else {
          if (el.observations) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
    if (el.readonly === 1) {
      if (props.mode === 2) {
        return true;
      }
      if (props.mode === 1) {
        return true;
      } else {
        if (parseInt(userId_02) !== parseInt(props.applicant)) {
          return true;
        } else {
          if (el.observations) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
  };

  return (
    <Col
      className={props.el.visibility.status ? "" : " d-none"}
      xs={12}
      md={props.el.width}
    >
      <Row>
        <Col xs={12}>
          <Form.Label
            className={
              "font-weight-bold mb-0" + (props.el.error ? "text-danger" : "")
            }
          >
            <span
              style={
                props.el.requiredSignal
                  ? { display: "inline" }
                  : { display: "none" }
              }
            >
              <b>* </b>
            </span>
            {props.el.label}
            <span
              className="observation-input-icon"
              style={{
                display:
                  props.el.is_validable && props.mode === 3
                    ? "inline-block"
                    : "none"
              }}
              onClick={() => props.setShow(props.el)}
            >
              <i
                className={
                  "far fa-comment-dots" +
                  (props.el.observations ? " text-danger" : "")
                }
              />
            </span>
          </Form.Label>
          <small
            style={{ padding: "5px" }}
            id={`small${props.el.name}`}
            className="form-text text-muted mt-0"
          >
            {props.el.contextual_help ? props.el.contextual_help : ""}
          </small>
          <DatePicker
            showPopperArrow={false}
            selected={startDate}
            onChange={date => onChangeEventHandler(date)}
            disabled={disableFieldBasedMethod(props.el)}
            dateFormat="dd-MM-yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText={new Date().toLocaleDateString("en-GB").replace(/\//g, '-')}
            className="form-control"
            locale={"es"}
          />
        </Col>
      </Row>
      {/*    <small
        id={`warning_small${props.el.name}`}
        className={props.hasErrors ? "form-text text-danger" : ""}
        style={props.hasErrors ? { display: "block" } : { display: "none" }}
      >
        {`Necesitas establecer la fecha despues del dia ${
          props.el.extra_attributes.date.min
        }  y antes del dia  ${props.el.extra_attributes.date.max}`}
      </small>
 */}
      {props.errorsCalendar}
    </Col>
  );
};

export default DynamicDatePicker;
