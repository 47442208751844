import React, { useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { Modal, Button } from "react-bootstrap";
import { confirmationEmail } from "./confirmation_email_logic";

import queryString from "query-string";

const ConfirmationEmail = props => {
  const getParams = queryString.parse(props.location.search);
  const [modalShow] = useState(true);

  const onSubmitEmail = async () => {
    const token = getParams.token;
    await confirmationEmail(token, props);
  };

  return (
    <section className="notification-panel">
      <Row center="xs">
        <Col xs={9} style={{ padding: "30px" }}>
          <Modal
            size="lg"
            show={modalShow}
            onHide={() => {}}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header className="bg-complement-3">
              <Modal.Title id="example-modal-sizes-title-lg">
                Confirmacion de correo electronico
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "2em" }}>
              <div style={{ paddingBottom: "2em" }}>
                Confirmación de correo exitosa! <br />A continuacion da clic en
                continuar para loguearte con el nuevo correo electrónico.
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={onSubmitEmail}>
                Continuar
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </section>
  );
};
export default ConfirmationEmail;
