import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";

const GroupButtonsForm = props => {
  return (
    <Row>
      <Col xs={12} sm={12} className="mT-20">
        <Link to={props.urlToCancel ? props.urlToCancel : "/"}>
          <Button
            style={{width: "45%"}}
            variant="secondary"
            type="button"
            className="p-5 group-button-submit btn-responsive mB-10@sm-"
          >
            {props.secondaryText}
          </Button>
        </Link>
        <Button
            style={{width: "45%"}}
            variant="primary"
            type="submit"
            className="p-5 group-button-submit float-right btn-responsive"
        >
          {props.primaryText}
        </Button>
      </Col>
    </Row>
  );
};

export default GroupButtonsForm;
