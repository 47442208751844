import Cookies from "js-cookie";
import { NewClassRequest } from "../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";

/**********************************************************SAVE AVATAR************************************************************************/
export const uploadAvatar = async file64 => {
  const { userId_02 } = Cookies.get();
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/upload_avatar/${userId_02}`,
    "post",
    null,
    {
      avatar: file64
    }
  );
  const uploadAvatarData = await request.executeRequest();

  if ( uploadAvatarData.code === 200 && uploadAvatarData.response.url ) {

    Cookies.set("avatar_02", uploadAvatarData.response.url);
    NotificationManager.success(uploadAvatarData.response.message);
    setTimeout(() => {
      window.location.href = '/home';
    }, 800);
  } else {
    NotificationManager.error('El archivo no fue cargado correctamente, inténtalo nuevamente');
  }
};
/***********************************************************************************************************************************************/
