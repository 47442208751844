import React from "react";
import { Button, FormControl } from "react-bootstrap";

const ExpedientsSearcher = (props) => {
  const {
    placeholderSearch,
    placeholderSearchButton,
    placeholderCleanButton,
    helperContextual,
    executeSearch,
    cleanSearch,
    isSearching,
    isCleaning,
    setSearch,
    search,
    classNameButtonsSearch,
    hasRulesToSearch,
    rulesToSearch
  } = props;

  const onEnterKey = (e) => {

    if (e.key === "Enter" && search.length > 0) {
      executeSearch();
    }
  };

  return (
    <>
      <section
        className="searcherContainer"
      >
        <FormControl
          placeholder={placeholderSearch}
          className="inputSearcher"
          style={{ fontSize: "1rem", width: "100%" }}
          onChange={(e) => {
            if (hasRulesToSearch && rulesToSearch.test(e.target.value)) {
              setSearch(e.target.value);
            } else if (!hasRulesToSearch) setSearch(e.target.value);
          }}
          value={search}
          onKeyDown={(e) => onEnterKey(e)}
        />
        <div className="clearFloat" />
        <div className="helperContextual" >
          <small>{helperContextual}</small>
        </div>
        <div className="clearFloat" />
        <div className={classNameButtonsSearch ? classNameButtonsSearch : "buttonsSearch"} >
          <Button
            variant="primary"
            className="px-4 mT-20 btn-responsive"
            onClick={() => executeSearch()}
            disabled={isSearching || isCleaning}
            style={isCleaning ? { display: "none" } : null}
          >
            {isSearching ? "Buscando..." : placeholderSearchButton}
          </Button>
          <Button
            variant="secondary"
            className="px-4 mT-20 btn-responsive"
            onClick={() => cleanSearch()}
            disabled={isCleaning || isSearching}
            style={isSearching ? { display: "none" } : null}
          >
            {isCleaning ? "Limpiando..." : placeholderCleanButton}
          </Button>
        </div>

      </section>
    </>
  );

};

export default ExpedientsSearcher;