import React from "react";
import {Grid} from "react-flexbox-grid";
import Footer from "../shared/Footer";
import NavBar from "../shared/NavBar";
import TopMenu from "../shared/TopMenu";
import Loader from "../shared/Loader";

const OneColumn = props => {

    const getTabs = () => {
        return !props.hideTopMenu ? (<TopMenu pageConfig={props.pageConfig}/>) : '';
    };

    const getTitle = () => {
        return !props.noTitle ? (
            <Grid className="pT-40 pB-20 title">
                <h2 className="text-justify">Bienvenido a tus trámites</h2>
            </Grid>
            ) : '';
    };

    return (
        <div className="one_column">
            <NavBar {...props} />

            { getTitle() }

            <Grid fluid={true} className="no-padding" style={{minHeight: '76vh'}}>
                { getTabs() }

                <Grid fluid={true} className="principal-container no-padding">
                    <Grid fluid={props.fluid} className={props.fluid ? 'p-0': ''}>{props.children}</Grid>
                </Grid>
            </Grid>

            < Loader/>
            < Footer/>
        </div>
    );
};

export default OneColumn;
