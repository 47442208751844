/* eslint-disable eqeqeq */
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { destroyCookies } from "../login/login_logic";

export class NewClassRequest {
  constructor(url, method, headers, bodyRequest) {
    this.url = url;
    this.bodyRequest = bodyRequest;
    this.method = method;
    this.headers = headers;
  }

  async executeRequest() {
    let responseError = {
      code: 800,
      message:
        "Hubo un error al realizar la accion, intentelo mas tarde o contacte al administrador."
    };
    const judicialBoletinRequests = window.location.pathname === '/boletin_judicial/consultar';
    const authenticationToken = Cookies.get( "authentication_token_02" ) && !judicialBoletinRequests ?
      String(Cookies.get( "authentication_token_02" )) :
      btoa( process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION );

    try {
      const response = await axios({
        method: this.method,
        url: this.url,
        data: this.bodyRequest,
        headers: {
          Authorization: authenticationToken
        }
      });
      const { clean } = response && response.data && response.data.data ? response.data.data : { clean: false };
      if ( clean ) {
        destroyCookies();
        // window.location.href = "/";
        return {
          code: 403,
          response: null,
          completeResponse: null
        };
      }

      if( response.status === 200 ){

        return {
          code: response.data.code,
          response: response.data.data,
          completeResponse: response
        }
      }

    } catch (error) {

        responseError = {
          code: 500,
          message: "El servidor no responde"
        };

        NotificationManager.error(
          responseError.message,
          "Error " + responseError.code + ":"
        );
    }

    return responseError;
  }
}
