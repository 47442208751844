import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const GoogleRecaptcha = props => {
  return (
      <ReCAPTCHA
        sitekey="6LcelJ8UAAAAAJY666BIbY8AO_Y_Tg5k9mL_SaTP"
        onChange={value => props.validate(value)}
      />
  );
};

export default GoogleRecaptcha;
