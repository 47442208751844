import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import GroupButtonsForm from "../helpers_components/GroupSubmitFormButtons";
import { register, initForm, termsRequest, ModalTermsAndConditions, getMunicipalities } from "./register_logic";
import GoogleRecaptcha from "../helpers_components/GoogleRecaptcha";
import Plain from "../layout/containers/Plain";
import LoaderContext from "../layout/shared/loader_context";
import { renderErrorsList, renderClassesToErrors } from "../confirm_registration/confirm_registration_logic";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import moment from "moment";
import CustomInput from "../search_to_citizens/CustomInputDate";
import { secondCustomHeaderDatePicker } from "../search_to_citizens/public_movements_logic";
moment.locale("es");
registerLocale("es", es);

const Register = props => {
  const [form, setForm] = useState(initForm);
  const Loader = useContext(LoaderContext);
  const [recaptcha, setRecaptcha] = useState("");
  const [handleTermsModal, setHandleTermsModal] = useState(false);
  const [termsText, setTermsText] = useState(false);
  const [termsTitle, setTermsTitle] = useState("");
  const [canShowModal, setCanShowModal] = useState(true);
  const [municipalities, setMunicipalities] = useState([]);
  const [errors, setErrors] = useState({});
  const firstInputDatePicker = useRef(null);
  const genders = [
    {
      label: 'Hombre',
      value: 'male'
    },
    {
      label: 'Mujer',
      value: 'female'
    },
    {
      label: 'Género no binario',
      value: 'no_binary'
    }
  ]

  useEffect(() => {
    (async function () {
      await termsRequest(setTermsTitle, setTermsText, setCanShowModal);
      await getMunicipalities(setMunicipalities);
    })();
  }, []);

  const handleChangeForm = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
    Object.keys(errors).forEach((error) => {
      if (error === event.target.name) {
        delete errors[event.target.name];
      }
    });
  };

  const onSubmitFormHandler = async event => {
    event.preventDefault();
    Loader.show(true);
    await register(form, recaptcha, props, setErrors);
    Loader.show(false);
  };

  const ModalToTermsAndConditions = ModalTermsAndConditions(
    handleTermsModal,
    setHandleTermsModal,
    termsText,
    termsTitle
  );

  return (
    <Plain {...props} noHeader={true} noFooter={true}>
      <Container
        fluid={true}
        className="min-height-100vh bg-size-cover"
        style={{
          backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)"
        }}
      >
        <Container>
          <Row style={{ minHeight: "100vh" }}>
            <Col
              sm={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                      >
                        <h3 className="mb-0">Crear Cuenta</h3>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={{ span: 10, offset: 1 }} className="pT-30">
                      <p className="mB-30">
                        Para registrarte, por favor completa el siguiente
                        formulario.
                      </p>

                      <Form onSubmit={onSubmitFormHandler} className="mB-20">
                        <Form.Group>
                          <Form.Label className="font-weight-bold">
                            * Regimen Fiscal
                          </Form.Label>
                          <Form.Control
                            name="regime"
                            onChange={handleChangeForm}
                            as="select"
                            required
                            defaultValue={''}
                          >
                            <option value={''} disabled>Regimen Fiscal...</option>
                            <option value="1">Persona Fisica</option>
                            <option value="2">Persona Moral</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formBasicNombre">
                          <Form.Label className="font-weight-bold">
                            * Nombre de Usuario
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Nombre de Usuario"
                            required
                            autoFocus
                            name="username"
                            onChange={handleChangeForm}
                            autoComplete="off"
                            className={renderClassesToErrors(errors, "username")}
                          />
                          {renderErrorsList(errors, "username")}
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoElectronico">
                          <Form.Label className="font-weight-bold">
                            * Correo
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Correo Electronico"
                            required
                            name="email"
                            onChange={handleChangeForm}
                            autoComplete="off"
                            className={renderClassesToErrors(errors, "email")}
                          />
                          {renderErrorsList(errors, "email")}
                        </Form.Group>

                        <Form.Group controlId="formBasicGender">
                          <Form.Label className="font-weight-bold">
                            * Género
                          </Form.Label>
                          <Form.Control
                            name="gender"
                            onChange={handleChangeForm}
                            as="select"
                            required
                            defaultValue=""
                          >
                            <option value="" disabled>Selecciona tu género...</option>
                            {
                              genders.map((g, i) => (
                                <option value={g.value} key={i}>
                                  {g.label}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {renderErrorsList(errors, "gender")}
                        </Form.Group>

                        <Form.Group controlId="formBasicAge">
                          <Form.Label className="font-weight-bold">
                            * Edad
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Ingresa tu edad"
                            required
                            name="age"
                            onChange={handleChangeForm}
                            autoComplete="off"
                            onInput={(e) => {
                              if (e.target.value < 1) e.target.value = ""
                              else e.target.value = Math.round(e.target.value);
                              e.target.value = e.target.value.slice(0,3);
                            }}
                            className={renderClassesToErrors(errors, "age")}
                          />
                          {renderErrorsList(errors, "age")}
                        </Form.Group>

                        <Form.Group controlId="formBasicBirthdate">
                          <Form.Label className="font-weight-bold">
                            * Fecha de nacimiento
                          </Form.Label>
                          <DatePicker
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled
                            }) => secondCustomHeaderDatePicker(
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled
                            )}
                            locale='es'
                            customInput={<CustomInput ref={firstInputDatePicker} />}
                            onChange={(selected) => handleChangeForm({
                              target: { 
                                name: "birthday", 
                                value: selected 
                              }
                            })}
                            selected={form["birthday"] ? moment(form["birthday"]).toDate() : ""}
                          />
                          
                          {renderErrorsList(errors, "birthday")}
                        </Form.Group>

                        <Form.Group>
                          <h4><b>Domicilio</b></h4>
                          <hr style={{ marginTop:'-5px' }}/>
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoStreet">
                          <Form.Label className="font-weight-bold">
                            * Calle
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Ingresa la calle donde vives"
                            required
                            name="street"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "street")}
                          />
                          {renderErrorsList(errors, "street")}
                        </Form.Group>

                        <Form.Group controlId="formBasicNumExt">
                          <Form.Label className="font-weight-bold">
                            * Número exterior
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Ingresa el número exterior de donde vives"
                            required
                            name="ext_number"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "ext_number")}
                          />
                          {renderErrorsList(errors, "ext_number")}
                        </Form.Group>

                        <Form.Group controlId="formBasicNumInt">
                          <Form.Label className="font-weight-bold">
                            Número interior
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Ingresa el número interior de donde vives"
                            name="int_number"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "int_number")}
                          />
                          {renderErrorsList(errors, "int_number")}
                        </Form.Group>

                        <Form.Group controlId="formBasicColony">
                          <Form.Label className="font-weight-bold">
                            * Colonia
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Ingresa el nombre de la colonia donde vives"
                            name="colony"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "colony")}
                          />
                          {renderErrorsList(errors, "colony")}
                        </Form.Group>

                        <Form.Group controlId="formBasicMunicipality">
                          <Form.Label className="font-weight-bold">
                            * Municipio
                          </Form.Label>
                          <Form.Control
                            name="municipality"
                            onChange={handleChangeForm}
                            as="select"
                            required
                            defaultValue={''}
                          >
                            <option value="" disabled>Selecciona el nombre del municipio donde vives...</option>
                            {
                              municipalities.map((m, i) => (
                                <option value={m.id} key={i}>
                                  {m.name}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {renderErrorsList(errors, "municipality")}
                        </Form.Group>

                        <Form.Group controlId="formBasicZipCode">
                          <Form.Label className="font-weight-bold">
                            * Código postal
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Ingresa el código postal de donde vives"
                            name="zip_code"
                            required
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "zip_code")}
                            pattern="\d{5}"
                            onInput={(e) => {
                              if (e.target.value < 1) e.target.value = ""
                              else e.target.value = Math.round(e.target.value);
                              e.target.value = e.target.value.slice(0,5);
                            }}
                          />
                          {renderErrorsList(errors, "zip_code")}
                        </Form.Group>
                        <hr/>

                        <Form.Group controlId="formBasicCorreoContraseña">
                          <Form.Label className="font-weight-bold">
                            * Contraseña
                          </Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Contraseña"
                            required
                            name="password"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "password")}
                          />
                          {renderErrorsList(errors, "password")}
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoConfirmarContraseña">
                          <Form.Label className="font-weight-bold">
                            * Confirmar contraseña
                          </Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirmar Contraseña"
                            required
                            name="password_confirmation"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "password_confirmation")}
                          />
                          {renderErrorsList(errors, "password_confirmation")}
                        </Form.Group>

                        <div>
                          <Link to="/">Ya tengo cuenta</Link>
                        </div>
                        <div>
                          <p>
                            Al registrarme, aseguro haber leído y aceptado los
                            <span
                              onClick={canShowModal ? () => {
                                setHandleTermsModal(true);
                              } : null}
                              className="cursor-pointer text-primary px-1"
                            >
                              términos y condiciones
                            </span>
                            del uso del sistema.
                          </p>
                        </div>

                        <div className="overflow-auto w-100">
                          <GoogleRecaptcha
                            validate={value => setRecaptcha(value)}
                          />
                        </div>
                        {!recaptcha ? renderErrorsList(errors, "recaptcha") : null}
                        <GroupButtonsForm
                          primaryText="Registrarme"
                          secondaryText="Cancelar"
                        />
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <>
        { ModalToTermsAndConditions}
      </>
    </Plain>
  );
};

export default Register;
