import Cookies from "js-cookie";
import { Request } from "../classes/Request";
import { NotificationManager } from "react-notifications";

////////////////////////////////////////////////////FUNCION QUE TRAE LOS DATOS DE LAS TABLAS/////////////////////////////////////////////////////////////////

export const deleteProcedureByUser = async (el, history) => {
  const { userId_02 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/delete/${userId_02}/${el.procedure_id}?token=${process.env.REACT_APP_TOKEN}`,
    "delete",
    null,
    {}
  );

  const deleteProcedureData = await request.executeRequest();

  if (deleteProcedureData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE TALOBILLO Y REGRESAR A FORMALITIES
    window.location.reload();
  } else if (
    typeof deleteProcedureData.data.data.message == "string" &&
    deleteProcedureData.data.data.message !== ""
  ) {
    NotificationManager.error(
      deleteProcedureData.data.data.message,
      "Error " + deleteProcedureData.data.code + ":"
    );
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
