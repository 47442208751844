import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Alert,
  Modal,
  Container,
  Row,
  Col,
  Image
} from "react-bootstrap";
import {
  finishLastStep,
  validateOnChangeValues,
  settingDefaultValues,
  evaluateVisibility,
  saveFileServer,
  isImage,
  iconUrl,
  iconUrlByExt
} from "./dynamic_form_logic";
import DynamicMap from "../helpers_components/dynamic_map/Map";
import DynamicSelect from "../helpers_components/dynamic_select/DynamicSelect";
import Cookies from "js-cookie";
import _ from "lodash";
import ObservationModal from "./observations/ObservationsModal";
import DigitalSignature from "../helpers_components/digital_signature/DigitalSignature";
import DigitalDocument from "../helpers_components/digital_document/DigitalDocument";
import Dropzone from "react-dropzone";
import DynamicDatePicker from "../helpers_components/date_picker/DatePicker";
import Muuri from "../muuri/Muuri";
import GridField from "../helpers_components/grid/GridField";
import LoaderContext from "../layout/shared/loader_context";

const DynamicForm = props => {
  const [state, setState] = useState({});
  const [dataForm, setDataForm] = useState([]);
  const [showLastForm, setShowLastForm] = useState(false);
  const [lastModalTitle] = useState("");
  const [statusLastModal] = useState("success");
  const [lastModalMessage] = useState("");
  const [previousUrl, setPreviousUrl] = useState("");
  const [observationModal, setObservationModal] = useState(false);
  const [previewFileImage, setPreviewFileImage] = useState(false);
  const [previewFileImgUrl, setPreviewImgUrl] = useState("");
  const [nextButtonDisable, setNextButtonDisable] = useState(false);
  //CUANDO ABRES LA NUBE AGREGA EL FIELD QUE SELECCIONASTE COMO EL ELEMENTO ACTIVO PARA PODER EJECUTAR LAS OBSERVACIONES
  const [activeElementValidable, setActiveElementValidable] = useState({});
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const Loader = useContext(LoaderContext);
  const [buttonDisabled, setButtonDisabled] = useState('');
  let fieldsWithDependentField = {};

  useEffect(() => {
    //ORDENAMOS EL FORMULARIO POR LA POSISION Y DEL FORMULARIO
    setPreviousUrl(props.previousUrl);
    const formsSort = props.form;
    let defaultValuesFormInit = {};
    setNextButtonDisable(false);

    if (!props.hasErrors) {
      setNextButtonDisable(false);
      defaultValuesFormInit = settingDefaultValues(
        formsSort,
        setNextButtonDisable,
        props.mode,
      );
      setState(defaultValuesFormInit);
    }

    // check visibility fields
    const formEvaluated = evaluateVisibility(
      formsSort,
      defaultValuesFormInit,
      null,
      null,
      null
    );
    //GUARDAMOS EN EL STATE EL VALOR DEL FORMULARIO QUE PASAMOS COMO PROP
    if (formEvaluated.length > 0) {
      setDataForm(formEvaluated);
    }
  }, [props.form, dataForm]);

  //CUANDO LE DA CLICK EN CONTINUAR
  const onSubmit = e => {
    e.preventDefault();
    setButtonDisabled('next');
    if (props.onSubmit) props.onSubmit(state);
  };

  //CUANDO EL USUARIO LE DA CLICK EN GUARDAR
  const onSaveHandler = e => {
    //Request para el siguiente formulario
    e.preventDefault();
    setButtonDisabled('save');
    if (props.onSubmit) props.onSubmit(state, true);
  };

  //CUANDO LE DA CLICK EN ANTERIOR
  const onPreviousHandler = e => {
    e.preventDefault();
    if (previousUrl) {
      setState({});
      props.setLastStep(false);
      props.setHasErrors(false);
      props.previousUrlHandler(previousUrl);
    }
  };

  const onChangeListCheckboxHandler = (event, checkbox, element) => {
    let nameCheckboxSelected = "";
    let valueCheckboxSelected = false;
    const newListCheckbox = state[element.name];
    const nameState = element.name;
    newListCheckbox.forEach(check => {
      if (typeof check[checkbox.name] !== "undefined") {
        nameCheckboxSelected = event.target.name;
        valueCheckboxSelected = event.target.checked;
        check[checkbox.name] = event.target.checked;
      }
    });
    setState({ ...state, [nameState]: newListCheckbox });
    evaluateVisibility(
      dataForm,
      state,
      valueCheckboxSelected,
      nameCheckboxSelected,
      "checkbox-list",
      element.name
    );
  };

  const onChangeHandler = (e, key, type = null, errorDate = null, setStateData = null) => {
    const valueInput = e;
    const elementName = key;
    const elementType = type;
    const elementErrorDate = errorDate;
    validateOnChangeValues(
      valueInput,
      elementName,
      elementType,
      elementErrorDate,
      state,
      setState,
      dataForm,
      setDataForm,
      setStateData
    );
  };

  const onObservationClickHandler = async el => {
    setActiveElementValidable(el);
    setObservationModal(true);
  };

  //CUANDO DAN CLICK AL BOTON DE CONFIRMAR EN EL ULTIMO FORMULARIO
  const finishStage = async () => {
    setButtonDisabled('finish');
    const { userId_02 } = Cookies.get();
    const stage_id = props.stage;
    Loader.show(true);
    await finishLastStep(stage_id, userId_02, props);
    Loader.show(false);
  };

  const setErrorByElement = el => {
    const errorMessages = el.error
      ? el.error.map((el, index) => {
          return (
            <li key={index}>
              <small className="text-danger">{el}</small>
            </li>
          );
        })
      : "";
    return errorMessages;
  };

  const createInputBaseTypeForm = el => {
    //Modos de formulario
    // 1 Edicion
    // 2 Read
    // 3 Validacion
    const disableFieldBasedMethod = el => {
      const { userId_02 } = Cookies.get();

      if (!el.readonly || el.readonly === 0) {
        if (props.mode === 2) {
          return true;
        }
        if (props.mode === 1) {
          return false;
        } else {
          if (parseInt(userId_02) !== parseInt(props.applicant)) {
            return true;
          } else {
            if (el.observations) {
              return false;
            } else {
              return true;
            }
          }
        }
      }
      if (el.readonly === 1) {
        if (props.mode === 2) {
          return true;
        }
        if (props.mode === 1) {
          return true;
        } else {
          if (parseInt(userId_02) !== parseInt(props.applicant)) {
            return true;
          } else {
            if (el.observations) {
              return false;
            } else {
              return true;
            }
          }
        }
      }
    };

    const onDrop = async (acceptedFiles, el) => {
      if (!disableUploadButton) {
        let file = acceptedFiles[0];
        const reader = new FileReader();
        setDisableUploadButton(true);

        reader.onload = async event => {
          setState({ ...state, [el.name + "_status"]: 1 });

          const fileSaved = await saveFileServer(
            event.target.result,
            props.history.location.state.params.stage,
            el
          );

          if (fileSaved) {
            setState({
              ...state,
              [el.name]: fileSaved.key,
              [el.name + "_img"]: fileSaved.url,
              [el.name + "_ext"]: fileSaved.extension,
              [el.name + "_loader"]: false,
              [el.name + "_status"]: 2
            });
          } else {
            setState({
              ...state,
              [el.name + "_status"]: 0
            });
          }

          setDisableUploadButton(false);
        };

        reader.readAsDataURL(file);
      }
    };

    const previewFileImgHandler = (state, el) => {
      const { userId_02 } = Cookies.get();
      let imageUrl = el["extra_attributes"]["show_watermark"] ? state[el.name + "_img"] + "&user_id=" + userId_02 : state[el.name + "_img"];
      setPreviewImgUrl(imageUrl);
      setPreviewFileImage(true);
    };

    const validateCloudVisibility = el => {
      if (el.is_validable && props.mode === 3) {
        if (el.observations) {
          return { display: "inline-block" };
        } else {
          return { display: "none" };
        }
      } else {
        return { display: "none" };
      }
    };

    switch (el.type) {
      case "text":
        const errorsInputText = setErrorByElement(el);
        return (
          <>
            <Col
              className={"" + (el.visibility["status"] ? "" : " d-none")}
              xs={12}
              md={el.width}
            >
              <Form.Group controlId={`inputForm.${el.name}`}>
                <Form.Label
                  className={
                    "font-weight-bold " + (el.error ? "text-danger" : "")
                  }
                >
                  <span
                    style={
                      el.requiredSignal
                        ? { display: "inline" }
                        : { display: "none" }
                    }
                  >
                    <b>* </b>
                  </span>
                  {el.label}
                  <span
                    className="observation-input-icon"
                    style={validateCloudVisibility(el)}
                    onClick={async () => onObservationClickHandler(el)}
                  >
                    <i
                      className={
                        "far fa-comment-dots" +
                        (el.observations ? " text-danger" : "")
                      }
                    />
                  </span>
                </Form.Label>
                <Form.Control
                  type={el.type}
                  disabled={disableFieldBasedMethod(el)}
                  name={el.name}
                  key={el.id}
                  onChange={e => onChangeHandler(e, el.name)}
                  value={state[el.name] || ""}
                  className={el.error ? "is-invalid" : ""}
                />
                {errorsInputText}

                <Form.Text className="text-muted">
                  {el.contextual_help ? el.contextual_help : ""}
                </Form.Text>
              </Form.Group>
            </Col>
          </>
        );
      case "title":
        return (
          <>
            <Col
              className={"" + (el.visibility["status"] ? "" : " d-none")}
              xs={12}
              md={el.width}
            >
              <div
                style={{
                  boxShadow: "0px 2px 2px 2px #EEEEEE",
                  padding: "20px",
                  borderRadius: "20px",
                  marginBottom: "3rem"
                }}
                dangerouslySetInnerHTML={{
                  __html: el.label
                }}
              ></div>
            </Col>
          </>
        );
      case "textarea":
        const errorsTextArea = setErrorByElement(el);
        const textareaHeight = el.height * 0.79 + "rem";

        return (
          <Col
            className={
              "h-100 mb-0" + (el.visibility["status"] ? "" : " d-none")
            }
            xs={12}
            md={el.width}
          >
            <Form.Group controlId={`inputForm.${el.name}`}>
              <Form.Label
                className={
                  "font-weight-bold " + (el.error ? "text-danger" : "")
                }
              >
                <span
                  style={
                    el.requiredSignal
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                >
                  <b>* </b>
                </span>
                {el.label}
                <span
                  className="observation-input-icon"
                  style={validateCloudVisibility(el)}
                  onClick={async () => onObservationClickHandler(el)}
                >
                  <i
                    className={
                      "far fa-comment-dots" +
                      (el.observations ? " text-danger" : "")
                    }
                  />
                </span>
              </Form.Label>

              <Form.Control
                as={el.type}
                rows="3"
                name={el.name}
                disabled={disableFieldBasedMethod(el)}
                key={el.id}
                onChange={e => onChangeHandler(e, el.name)}
                value={state[el.name] || ""}
                className={el.error ? "is-invalid" : ""}
                style={{ height: textareaHeight, resize: "none" }}
              />
              {errorsTextArea}
              <Form.Text className="text-muted">
                {el.contextual_help ? el.contextual_help : ""}
              </Form.Text>
            </Form.Group>
          </Col>
        );
      case "check-box":
        const errorsCheckbox = setErrorByElement(el);
        const isMultipleCheckbox = _.has(el.extra_attributes, "check");
        //SI ES UN CHECK-BOX PERO ES UNA LISTA DE CHECKBOX
        if (isMultipleCheckbox) {
          let checks = {};
          const list = el.extra_attributes.check.map((element, index) => {
            // eslint-disable-next-line array-callback-return
            (state[el.name] ? state[el.name] : []).find(check => {
              checks = { ...checks, ...check };
            });

            return (
              <div className="form-check mb-1 ml-2" key={index}>
                <input
                  type="checkbox"
                  className={
                    element.error
                      ? "form-check-input is-invalid"
                      : "form-check-input"
                  }
                  disabled={disableFieldBasedMethod(el)}
                  id={element.name}
                  key={element.id}
                  name={element.name}
                  checked={checks[element.name]}
                  onChange={event =>
                    onChangeListCheckboxHandler(event, element, el)
                  }
                />
                <label
                  className={
                    el.error
                      ? "form-check-label text-danger"
                      : "form-check-label"
                  }
                  htmlFor={element.name}
                >
                  {element.label}
                </label>
              </div>
            );
          });
          return (
            <Col
              className={el.visibility["status"] ? "" : "p-10 d-none"}
              xs={12}
              md={el.width}
            >
              <Col xs={12}>
                <div className="form-group mb-2">
                  <label
                    className={
                      "font-weight-bold " +
                      (el.error
                        ? "form-check-label text-danger"
                        : "")
                    }
                    htmlFor={`${el.name}_checkboxlist_label`}
                    style={{marginLeft:"-11px"}}
                  >
                    <span
                      style={
                        el.requiredSignal
                          ? { display: "inline" }
                          : { display: "none" }
                      }
                    >
                      <b>* </b>
                    </span>
                    {el.label}
                    <span
                      className="observation-input-icon"
                      style={validateCloudVisibility(el)}
                      onClick={async () => onObservationClickHandler(el)}
                    >
                      <i
                        className={
                          "far fa-comment-dots" +
                          (el.observations ? " text-danger" : "")
                        }
                      />
                    </span>
                  </label>
                </div>
              </Col>
              <Col xs={12} sm={12}>
                {list}
              </Col>

              <Col xs={12} sm={12}>
                <small
                  id={`small${el.name}`}
                  className="form-text text-muted mt-1"
                >
                  {el.contextual_help ? el.contextual_help : ""}
                </small>
              </Col>

              {errorsCheckbox}
            </Col>
          );
        } else {
          //SI ES UN CHECK-BOX SINGULAR, SOLAMENTE UN CAMPO DECLARADO EN EL MODELADO
          return (
            <Col
              xs={12}
              md={el.width}
              className={ el.visibility["status"] ? "" : "p-10 d-none" }
            >
              <Col xs={12}>
                <div className="form-group mb-2">
                  <label
                    className="font-weight-bold form-check-label mb-1"
                    htmlFor={`${el.name}_checkbox_label`}
                    style={{marginLeft:"-11px"}}
                  >
                    <span
                      style={
                        el.requiredSignal
                          ? { display: "inline" }
                          : { display: "none" }
                      }
                    >
                      <b>* </b>
                    </span>
                    {el.label}
                    <span
                      className="observation-input-icon"
                      style={validateCloudVisibility(el)}
                      onClick={async () => onObservationClickHandler(el)}
                    >
                      <i
                        className={
                          "far fa-comment-dots" +
                          (el.observations ? " text-danger" : "")
                        }
                      />
                    </span>
                  </label>
                  <div className="form-check mb-1 ml-2">
                    <input
                      className={
                        el.error
                          ? "form-check-input is-invalid"
                          : "form-check-input"
                      }
                      type="checkbox"
                      disabled={disableFieldBasedMethod(el)}
                      id={el.name}
                      key={el.id}
                      name={el.name}
                      checked={state[el.name] || false}
                      onChange={e => onChangeHandler(e, el.name, el.type)}
                      required
                    />
                    <label
                      className={
                        el.error
                          ? "form-check-label text-danger"
                          : "form-check-label"
                      }
                      htmlFor={el.name}
                    >
                      {el.label}
                    </label>
                  </div>

                  <small
                    id={`small${el.name}`}
                    className="form-text text-muted mt-2"
                  >
                    {el.contextual_help ? el.contextual_help : ""}
                  </small>

                  {errorsCheckbox}
                </div>
              </Col>
            </Col>
          );
        }

      case "subtitle":
        return (
          <Col
            xs={12}
            md={el.width}
            className={"" + (el.visibility["status"] ? "" : " d-none")}
          >
            <h6 key={el.id} id={el.id}>
              {el.label}
            </h6>
          </Col>
        );
      case "radio-button":
        const errorsRadio = setErrorByElement(el);
        return (
          <>
          <Col
            xs={12}
            className={"" + (el.visibility["status"] ? "" : " d-none")}
            md={el.width}
          >
            <div>
              <label className="font-weight-bold mb-0">
                <span
                  style={
                    el.requiredSignal
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                >
                  <b>* </b>
                </span>
                {el.label}
              </label>

              <span
                className="observation-input-icon"
                style={validateCloudVisibility(el)}
                onClick={async () => onObservationClickHandler(el)}
              >
                <i
                  className={
                    "far fa-comment-dots" +
                    (el.observations ? " text-danger" : "")
                  }
                />
              </span>
            </div>
            {el.extra_attributes.options.map((option, index) => {
              return (
                <Col xs={12} md={el.width} key={index}>
                  <div className="form-check">
                    <input
                      className={
                        el.error
                          ? "form-check-input is-invalid"
                          : "form-check-input"
                      }
                      disabled={disableFieldBasedMethod(el)}
                      type="radio"
                      name={el.name}
                      id={`${option.label}-radio`}
                      checked={option.value === state[el.name] ? true : false}
                      onChange={e =>
                        onChangeHandler(e, el.name, el.type, option.value)
                      }
                    />
                    <label
                      className={
                        el.error
                          ? "form-check-label text-danger"
                          : "form-check-label"
                      }
                      htmlFor={`${option.label}-radio`}
                    >
                      {option.label}
                    </label>
                  </div>
                </Col>
              );
            })}

            <Col xs={12} sm={12}>
              <small
                id={`small${el.name}`}
                className="form-text text-muted mt-1"
              >
                {el.contextual_help ? el.contextual_help : ""}
              </small>
            </Col>
            {errorsRadio}
          </Col>
          </>
        );

      case "paragraph":
        return (
          <Col
            className={
              "h-100 mb-0 mT-10 overflow-auto paragraph-wrapper" +
              (el.visibility["status"] ? "" : " d-none")
            }
            xs={12}
            md={el.width}
          >
            <label className="font-weight-bold">{el.label}</label>
            <p
              className="break-word"
              dangerouslySetInnerHTML={{
                __html: el.extra_attributes.content
              }}
            />
          </Col>
        );

      case "redirect":
        return (
          <Col 
            className={ el.visibility["status"] ? "" : "d-none" }
            xs={12}
            md={ el.width }
          >
            <label className="font-weight-bold">{el.label}</label>
            <div>
              <Button>
                <a
                  href={el.extra_attributes.url}
                  target={el.extra_attributes.target}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  {el.extra_attributes.url_label}
                </a>
              </Button>
            </div>
            <div className="w-100">
              <small id="redirectHelp" className="form-text text-muted">
                {el.contextual_help ? el.contextual_help : ""}
              </small>
            </div>
          </Col>
        );

      case "hidden":
        return (
          <>
            <Form.Group controlId={`inputForm.${el.name}`}>
              <Form.Control
                type="text"
                name={el.name}
                key={el.id}
                value={el.default_value ? el.default_value : ""}
                onChange={() => {}}
                style={{ visibility: "hidden" }}
              />
            </Form.Group>
          </>
        );

      case "file":
        const { userId_02 } = Cookies.get();
        let fileData = {
          route: state[el.name + "_img"]
            ? state[el.name + "_img"]
            : el.file_route,
          extension: state[el.name + "_ext"]
            ? state[el.name + "_ext"]
            : el.format,
          status: state[el.name + "_status"]
            ? state[el.name + "_status"]
            : el.file_route
            ? 2
            : 0,
          isImage: isImage(
            state[el.name + "_ext"] ? state[el.name + "_ext"] : el.format
          ),
          icon: state[el.name + "_ext"]
            ? iconUrl(state, el.name)
            : iconUrlByExt(el.format)
        };
        fileData['route'] = el['extra_attributes']['show_watermark'] ? fileData['route'] + '&user_id=' + userId_02 : fileData['route'];

        return (
          <Col
            className={el.visibility["status"] ? "" : " d-none"}
            xs={12}
            md={el.width}
          >
            <Col xs={12}>
              <Form.Label
                className={
                  "font-weight-bold " + (el.error ? "text-danger" : "")
                }
              >
                <span
                  style={
                    el.requiredSignal
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                >
                  <b>* </b>
                </span>
                {el.label}
                <span
                  className="observation-input-icon"
                  style={validateCloudVisibility(el)}
                  onClick={() => onObservationClickHandler(el)}
                >
                  <i
                    className={
                      "far fa-comment-dots" +
                      (el.observations ? " text-danger" : "")
                    }
                  />
                </span>
              </Form.Label>
              <Form.Group
                style={disableFieldBasedMethod(el) ? { display: "none" } : null}
                controlId="formBasicFile"
              >
                <Dropzone onDrop={event => onDrop(event, el)}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="file-nilo-wrapper" {...getRootProps()}>
                      Seleccionar un archivo
                      <input
                        style={{ display: "inline!important" }}
                        className={
                          el.error ? " form-control is-invalid" : "form-control"
                        }
                        {...getInputProps()}
                        disabled={
                          disableFieldBasedMethod(el) || disableUploadButton
                        }
                        name={el.name}
                        key={el.id}
                      />
                    </div>
                  )}
                </Dropzone>
                <small
                  id={`small${el.name}`}
                  className="form-text text-muted mt-2"
                >
                  {el.contextual_help ? el.contextual_help : ""}
                </small>
                <span
                  style={
                    el.error
                      ? { display: "inline", color: "red" }
                      : { display: "none" }
                  }
                >
                  {el.error ? "* " + el.error[0] : ""}
                </span>
              </Form.Group>

              <div
                className="file-upload-wrapper"
                style={
                  fileData.isImage &&
                  (fileData.status === 2 || fileData.status === 1)
                    ? { display: "inline-block" }
                    : { display: "none" }
                }
              >
                <div
                  className={
                    "file-upload-actions " +
                    (fileData.status === 2 ? "d-block" : "d-none")
                  }
                >
                  <i
                    className="fas fa-search-plus"
                    style={{ cursor: "pointer" }}
                    title="preview image"
                    onClick={() => previewFileImgHandler(state, el)}
                  />
                  <i
                    className="fas fa-cloud-download-alt"
                    style={{ cursor: "pointer" }}
                    title="dowload file"
                    onClick={() => {
                      window.open(`${fileData.route}`, "_blank");
                    }}
                  />
                </div>
                <img
                  className={
                    "loader " + (fileData.status === 1 ? "d-block" : "d-none")
                  }
                  src={process.env.PUBLIC_URL + "/img/loader.gif"}
                  alt="Cargando ..."
                />
                <img
                  src={fileData.route}
                  alt="File"
                  className={fileData.status === 2 ? "d-block" : "d-none"}
                  style={
                    state[el.name]
                      ? { display: "inline-block" }
                      : { display: "none" }
                  }
                />
              </div>

              <div
                className={
                  ( 
                    !fileData.isImage && ( fileData.status === 2 ) 
                    ? "file-upload-wrapper d-block"
                    :  !fileData.isImage && ( fileData.status === 1 ) 
                    ?  "file-icon-wrapper d-block" 
                    : "d-none"
                  )
                }
              >
                <img
                  className={
                    "loader w-100 " +
                    (fileData.status === 1 ? "d-block" : "d-none")
                  }
                  src={process.env.PUBLIC_URL + "/img/loader.gif"}
                  alt="Cargando ..."
                />
                <a
                  className={fileData.status === 2 ? "d-block" : "d-none"}
                  href={fileData.route}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={fileData.route}
                >
                  <img
                    className={fileData.status === 2 ? "d-block" : "d-none"}
                    src={fileData.icon}
                    alt=""
                  />
                </a>
              </div>
            </Col>
          </Col>
        );
      case "date":
        const errorsCalendar = setErrorByElement(el);
        return (
          <DynamicDatePicker
            el={el}
            hasErrors={el.error}
            errorsCalendar={errorsCalendar}
            onChangeHandler={onChangeHandler}
            state={state}
            setState={setState}
            mode={props.mode}
            setShow={onObservationClickHandler}
            show={observationModal}
            applicant={props.applicant}
          />
        );

      case "map":
        const errorsMap = setErrorByElement(el);

        if (el.visibility.status)
          return (
            <DynamicMap
              el={el}
              onChangeHandler={onChangeHandler}
              hasErrors={el.error}
              errorsMap={errorsMap}
              mode={props.mode}
              setShow={onObservationClickHandler}
              show={observationModal}
              applicant={props.applicant}
            />
          );
      break;

      case "select":
        const errorSelect = setErrorByElement(el);
        return (
          <DynamicSelect
            el={el}
            onChangeHandler={onChangeHandler}
            hasErrors={el.error}
            errorSelect={errorSelect}
            state={state}
            setState={setState}
            mode={props.mode}
            setShow={onObservationClickHandler}
            show={observationModal}
            applicant={props.applicant}
            fieldsWithDependentField={fieldsWithDependentField}
          />
        );

      case "digital-signature":
        const errorDigital = setErrorByElement(el);
        return (
          <DigitalSignature
            el={el}
            onChangeHandler={onChangeHandler}
            hasErrors={el.error}
            errorDigital={errorDigital}
            state={state}
            setState={setState}
            mode={props.mode}
            stage={props.stage}
            showModalSignature=""
            showDowloadDocument=""
            finalDocumentUrlPDF=""
            setFinalDocumentUrlPDF=""
            setNextButtonDisable={setNextButtonDisable}
            setSignInStep=""
          />
        );

      case "digital-document":
        const errorDocument = setErrorByElement(el);
        return (
          <DigitalDocument
            el={el}
            onChangeHandler={onChangeHandler}
            hasErrors={el.error}
            errorDocument={errorDocument}
            state={state}
            setState={setState}
            mode={props.mode}
            stage={props.stage}
            setNextButtonDisable={setNextButtonDisable}
          />
        );

      case "grid":
        return (
          <GridField
            el={el}
            onChangeHandler={onChangeHandler}
            state={state}
            setState={setState}
            mode={props.mode}
            stage={props.stage}
            setNextButtonDisable={setNextButtonDisable}
          />
        );

      default:
        break;
    }
  };

  const buttonToolbar = () => {
    if (!props.lastStep) {
      if (!props.previousUrl) {
        return (
          <Container className="mT-20 dynamic-form-toolbar">
            <Row>
              <div className="p-30 w-100 ">
                <span className="form-text text-muted text-center">
                  <b>( * ) Campos requeridos.</b>
                </span>
              </div>
              <Col xs={12} sm={12}>
                <Button
                  variant="dark"
                  type="button"
                  className="d-block float-left px-4"
                  onClick={() => props.history.push("/formalities")}
                >
                  Cancelar
                </Button>
                <Button
                  variant="success"
                  type="button"
                  onClick={onSubmit}
                  className="d-block float-right px-4"
                  disabled={nextButtonDisable || (Loader.status && buttonDisabled === 'next')}
                >
                  { Loader.status && buttonDisabled === 'next' ? 
                    'Ejecutando...' : 
                    'Siguiente'}
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  onClick={onSaveHandler}
                  className="d-block float-right px-4 mR-20"
                  disabled={Loader.status && buttonDisabled === 'save'}
                >
                  { Loader.status && buttonDisabled === 'save' ? 
                    'Guardando...' : 
                    'Guardar'}
                </Button>
              </Col>
            </Row>
          </Container>
        );
      } else {
        return (
          <Container className="mT-20 dynamic-form-toolbar">
            <Row>
              <div className="p-30 w-100 ">
                <span className="form-text text-muted text-center">
                  <b>( * ) Campos requeridos.</b>
                </span>
              </div>
              <Col xs={12} md={12}>
                <Button
                  variant="dark"
                  type="button"
                  className="d-block float-left px-4"
                  onClick={() => props.history.push("/formalities")}
                >
                  Cancelar
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={onPreviousHandler}
                  className="d-block float-left px-4 mL-20"
                >
                  Anterior
                </Button>
                <Button
                  variant="success"
                  type="button"
                  onClick={onSubmit}
                  className="d-block float-right px-4"
                  disabled={nextButtonDisable || (Loader.status && buttonDisabled === 'next')}
                >
                  { Loader.status && buttonDisabled === 'next' ? 
                    'Ejecutando...' : 
                    'Siguiente'}
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  onClick={onSaveHandler}
                  className="d-block float-right px-4 mR-20"
                  disabled={Loader.status && buttonDisabled === 'save'}
                >
                  { Loader.status && buttonDisabled === 'save' ? 
                    'Guardando...' : 
                    'Guardar'}
                </Button>
              </Col>
            </Row>
          </Container>
        );
      }
    } else {
      return (
        <Container className="mT-20 dynamic-form-toolbar">
          <Row end="xs">
            <Col xs={12} md={12}>
              <Button
                variant="dark"
                type="button"
                className="d-block float-left px-4"
                onClick={() => props.history.push("/formalities")}
              >
                Cancelar
              </Button>
              <Button
                variant="secondary"
                type="button"
                className="d-block float-left px-4 mL-20"
                onClick={onPreviousHandler}
              >
                Anterior
              </Button>
              <Button
                variant="primary"
                type="button"
                onClick={finishStage}
                className="d-block float-right px-4"
                disabled={Loader.status && buttonDisabled === 'finish'}
              >
                { Loader.status && buttonDisabled === 'finish' ? 
                  'Ejecutando...' : 
                  'Confirmar'}
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={previewFileImage}
        onHide={() => setPreviewFileImage(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton className="bg-complement-3">
          <Modal.Title id="example-modal-sizes-title-lg">
            Previsualización de imagen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ta-c">
            <Image src={previewFileImgUrl} fluid />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setPreviewFileImage(false)}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <ObservationModal
        show={observationModal}
        setShow={setObservationModal}
        el={activeElementValidable}
        stage={props.history.location.state.params.stage}
      />
      <Modal show={showLastForm} size="lg">
        <Modal.Header>
          <Modal.Title>{lastModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={statusLastModal}>
            <Alert.Heading>{lastModalTitle}</Alert.Heading>
            <p>{lastModalMessage}</p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={
              statusLastModal === "success"
                ? () => props.history.push("/home")
                : () => setShowLastForm(false)
            }
          >
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <h3 className="text-center mB-30 mT-40">{props.formTitle}</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {dataForm.length > 0 ? (
                <Muuri
                  dataForm={dataForm}
                  createInputBaseTypeForm={createInputBaseTypeForm}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}> {buttonToolbar()}</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DynamicForm;
