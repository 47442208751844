import React from "react";
import { Grid, Col, Row } from "react-flexbox-grid";

import Footer from "../shared/Footer";
import NavBar from "../shared/NavBar";
import Loader from "../shared/Loader";

const Plain = props => {
  if (!props.noHeader) {
    return (
      <div className="one_column plain">
        <NavBar {...props} />

        <Grid className="pT-70">
          <Row>
            <Col xs={12} sm={12}>
              {props.children}
            </Col>
          </Row>
        </Grid>

        <Loader />

        <div className={props.noFooter ? "d-none" : ""}>
          <Footer />
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        "one_column plain " +
        (props.backgroundImage ? "login-background-image" : "")
      }
    >
      {props.children}

      <Loader />

      <div className={props.noFooter ? "d-none" : ""}>
        <Footer />
      </div>
    </div>
  );
};

export default Plain;
