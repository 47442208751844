import React, { forwardRef } from 'react';

const CustomInput = (props, ref) => (
    <input
      ref={ref}
      onClick={props.onClick}
      value={props.value}
      type='text'
      onInput={(e) => e.preventDefault()}
      className='form-control'
      onChange={() => {}}
      placeholder='Da clic y elige la fecha correspondiente'
    />
  );
  
export default forwardRef(CustomInput);