import React from "react";
import { NewClassRequest } from "../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";
export const initForm = {
  curp: "",
  paternal_surname: "",
  maternal_surname: "",
  names: "",
  rfc: "",
  email: "",
  phone: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
// ###REFACTORIZADA
export const finisRegistration = async (form, confirmation_token, props, setErrors) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/finish_registration`,
    "put",
    null,
    {
      confirmation_token: confirmation_token,
      curp: form.curp,
      names: form.names,
      paternal_surname: form.paternal_surname,
      maternal_surname: form.maternal_surname,
      rfc: form.rfc,
      phone: form.phone
    }
  );
  const validateConfirmRegistration = await request.executeRequest();

  if ( validateConfirmRegistration.code !== 200 ) {

    setErrors( validateConfirmRegistration.response.message );
    NotificationManager.error( "Existen errores que impiden continuar, revisa los campos marcados en rojo" );
  }
  else if ( validateConfirmRegistration.code === 200 ) {

    if ( validateConfirmRegistration.response.error ) {

      setErrors( validateConfirmRegistration.response.message );
      NotificationManager.error( "Existen errores que impiden continuar, revisa los campos marcados en rojo" );
    }
    else {
      setTimeout(() => {
        NotificationManager.success("¡Ahora ya puedes Iniciar sesión!");
      }, 500);
      props.history.push("/");
    }
  }

};

/***********************************************************************************************************************************************/

/**********************************************************FORGOT PASSWORD************************************************************************/
// ###REFACTORIZADA
export const getUserConfirmData = async (tokenUrl, setUser) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/confirm`,
    "post",
    null,
    {
      confirmation_token: tokenUrl
    }
  );
  const getUserConfirmData = await request.executeRequest();

  if ( getUserConfirmData.code !== 200 ) {

    NotificationManager.error( getUserConfirmData.response.message, "Error " + getUserConfirmData.response.code + ": " );
  }
  else if ( getUserConfirmData.code === 200 ) {

    if (
      getUserConfirmData.response.error &&
      typeof getUserConfirmData.response.message === "string"
    ) {

      NotificationManager.error( getUserConfirmData.response.message, "Error " + getUserConfirmData.response.code + ": " );
    }
    else {

      getUserConfirmData.response.user.user_type =
          getUserConfirmData.response.user.user_type === "manager"
          ? "Gestor"
          : "Interesado";
      setUser(getUserConfirmData.response.user);

      NotificationManager.success(
        "Por favor completa la información solicitada para finalizar con el registro", 
        "Estimad@ " + getUserConfirmData.response.user.username + ": " 
      );
    }

  }
};

/***********************************************************************************************************************************************/

export const renderErrorsList = (errors, inputName) => {
  if ( errors && errors[inputName] ) {
    const errorsList = errors[inputName].map((error, index) => {
      return (
        <li key={index} style={{padding:"0px 25px 0px", color:"red", fontSize:".8rem"}}>
          <small>
            {error}
          </small>
        </li>
      );
    })
    return errorsList;
  }
};

export const renderClassesToErrors = (errors, inputName) => {
  if ( errors && errors[inputName] ) {
    return "text-danger is-invalid";
  }
};