import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Grid } from "react-flexbox-grid";
import { Button, ButtonGroup, Card } from "react-bootstrap";
import _ from "lodash";

//Local imports
import {
  getSectionsByProcess,
  getSectionContent,
  startProcess
} from "./procedure_logic";
import OneColumn from "../layout/containers/OneColumn";
import LoaderContext from "../layout/shared/loader_context";

const Procedure = props => {
  //State declaration
  const {
    props: {
      match: { params }
    }
  } = props;

  const procedureId = params.id;
  const [tabs, setTabs] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [cardTitle, setCardTitle] = useState("");
  const [cardContent, setCardContent] = useState("");
  const Loader = useContext(LoaderContext);

  const buttonStylesSelected = {
    color: "#fff", 
    backgroundColor:"#4868cf", 
    borderRadius: "3px",
    height: "40px"
  };

  const buttonStylesNotSelected = {
    color: "#fff", 
    backgroundColor: "#1d78ff",
    borderColor: "#1d78ff",
    borderRadius: "5px",
    height: "40px"
  };

  let pageConfig = {
    active: "my_formalities",
    title: "Tramites"
  };

  //Use effect first render call to api for data
  useEffect(() => {
    (async function() {
      const response = await getSectionsByProcess(procedureId);
      if (response.code === 200) {
        setTabs(_.sortBy(response.data.data.data.sections, el => el.position));
        if ( response.data.data.data.sections.length > 0 ) {
          const responseFirstSection = await getSectionContent(
            response.data.data.data.sections[0].id, 
            procedureId
          );
          if ( responseFirstSection.code === 200 ) {
            setCardTitle(responseFirstSection.data.data.data.section.name);
            setCardContent(
            responseFirstSection.data.data.data.section.content ?
            responseFirstSection.data.data.data.section.content :
            ""
            );
            setShowCard(true);
          }
        }

        Loader.show(false);
      }
    })();
  }, []);

  //click handler make a request to the api for populate the section
  const changeHandler = async (id, name, event, index) => {
    event.persist();
    const response = await getSectionContent(id, procedureId);
    if (response.code === 200) {
      setCardTitle(name);
      if (response.data.data.data.section.content) {
        setCardContent(response.data.data.data.section.content);
      } else {
        setCardContent("");
      }
      setShowCard(true);
    }
    const lengthOfButtons = event.target.parentElement.children.length;
    const buttonsSections = event.target.parentElement.children;
    const clickedButton = event.target;
    for ( let i = 0; i < lengthOfButtons; i++ ) {
      if ( i !== index ) {
        buttonsSections[i].style.backgroundColor = "#1d78ff";
      }
      else clickedButton.style.backgroundColor = "#4868cf";
    };
  };

  //Set the tabs component populate
  const buttonTabs = tabs.map((el, index) => {
    return (
      <button
        key={index}
        className="mr-2"
        style={ index === 0 ? buttonStylesSelected : buttonStylesNotSelected }
        onClick={(event) => changeHandler(el.id, el.name, event, index)}
      >
        {el.name}
      </button>
    );
  });

  //Submit start process request
  const initProcess = async () => {
    Loader.show(true);
    await startProcess(procedureId, props.props);
    Loader.show(false);
  };



  return (
    <OneColumn {...props} pageConfig={pageConfig} noTitle={true}>
      <Grid className="formalities">
        <Row bottom="xs" center="xs">
          <Col xs={12}>
            <h3 className="text-center mB-20">Tramites</h3>
            <Row xs={12} end="xs" className="p-10">
              <Col xs={12}>
                <Button
                  className="btn btn-success shadow-light"
                  onClick={initProcess}
                >
                  <i className="fas fa-paste" /> Iniciar tramite
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <ButtonGroup
                  aria-label="Basic example"
                  className="btn-tabs-group"
                >
                  <Col xs={12}>{buttonTabs}</Col>
                </ButtonGroup>
              </Col>
            </Row>
            <Row style={showCard ? { display: "block" } : { display: "none" }}>
              <Col xs={12} sm={12}>
                <Card
                  style={{ marginTop: "50px" }}
                >
                  <Card.Header>{cardTitle}</Card.Header>
                  <Card.Body>
                    <div dangerouslySetInnerHTML={{ __html: cardContent }} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </OneColumn>
  );
};

export default Procedure;
