import { Request } from "../classes/Request";

/**********************************************************FORGOT PASSWORD************************************************************************/

export const getAllAvaliableProces = async (url, searchValue = '') => {
  const request = url
    ? new Request(
        url + `&token=${process.env.REACT_APP_TOKEN}`,
        "get",
        null,
        {}
      )
    : new Request(
        `${process.env.REACT_APP_URL_API_LARAVEL}/available_processes/${searchValue}?token=${process.env.REACT_APP_TOKEN}`,
        "get",
        null,
        {}
      );

  const avaliableProcess = await request.executeRequest();

  if (avaliableProcess.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    //NOTA: DESPUES DE MOSTRAR EL MODAL REDIRECCIONAR AL LOGIN PARA REGISTRARSE CON EL USUARIO SI FUE SATISFACTORIO
    return {
      code: 200,
      previous_url: avaliableProcess.data.data.data.previous_url,
      next_url: avaliableProcess.data.data.data.next_url,
      processes: avaliableProcess.data.data.data.processes,
      current_page: avaliableProcess.data.data.data.current_page,
      first_url: avaliableProcess.data.data.data.first_url,
      last_url: avaliableProcess.data.data.data.last_url,
      total: avaliableProcess.data.data.data.total
    };
  } else {
    // MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    return avaliableProcess;
  }
};

/***********************************************************************************************************************************************/
