import React, { useContext,useEffect } from "react";
import { useLastLocation } from "react-router-last-location";
import Cookies from "js-cookie";
import _ from "lodash";
import { printUserNotification } from "../alert_notification_table/alert_notification_logic";
import notificationContext from '../alert_notification_table/notification_context';
import OneColumn from "../layout/containers/OneColumn";
import {Col, Row} from "react-flexbox-grid";

const Home = props => {
  let isAuth =
    _.has(Cookies.get(), "token_02") &&
    _.has(Cookies.get(), "uid_02") &&
    _.has(Cookies.get(), "client_02")
      ? true
      : false;
  
  let notificationCtxt = useContext(notificationContext);
  useEffect(() => {
    if( notificationCtxt.count > 0 ){
      printUserNotification();
    }
  }, []);

  let pageConfig = {
    active: 'home',
    title: 'Home'
  };

  const lastLocation = useLastLocation();
  if (lastLocation) {
    if (lastLocation.pathname === "/") {
      window.location.reload();
    }
  }
  if (!isAuth) {
    props.history.push("/");
  }

  return (
    <OneColumn {...props} pageConfig={pageConfig}>
      <section className="home">
        <Row center="xs">
          <Col xs={12}>
          </Col>
        </Row>
      </section>
    </OneColumn>
  );
};

export default Home;
