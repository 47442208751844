import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { deleteProcedureByUser } from "./delete_procedure_logic";

const DeleteProcedure = props => {
  const [showModal, setShowModal] = useState(false);

  const deleteAction = () => {
    deleteProcedureByUser(props.el);
    setShowModal(false);
  };

  return (
    <>
      <Modal
        size="md"
        show={showModal}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="bg-complement-3">
          <Modal.Title id="example-modal-sizes-title-sm">Advertencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Esta seguro de eliminar este elemento?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={deleteAction}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="trash" onClick={() => setShowModal(true)}>
        <i className="ti-trash" title="Eliminar" />
      </div>
    </>
  );
};

export default DeleteProcedure;
