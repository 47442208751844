import { Request } from "../classes/Request";
import { NotificationManager } from "react-notifications";

/**********************************************************FORGOT PASSWORD************************************************************************/

export const confirmationEmail = async (token, props) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API}/users/email/confirmation`,
    "patch",
    null,
    {
      confirmation_token: token,
      token: process.env.REACT_APP_TOKEN
    }
  );

  const confirmationEmailData = await request.executeRequest();
  if (confirmationEmailData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE REDIRIGIR AL LOGIN Y MOSTRAR UN MENSAJE
    if (
      typeof confirmationEmailData.data.data.data.message == "string" &&
      confirmationEmailData.data.data.data.message !== ""
    )
      setTimeout(() => {
        NotificationManager.success(
          confirmationEmailData.data.data.data.message
        );
      }, 1000);
    props.history.push("/home");
  }
};

/***********************************************************************************************************************************************/
