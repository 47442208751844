import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getObservations } from "./observation_logic";

const ObservationModal = props => {
  // eslint-disable-next-line no-unused-vars
  const [element, setElement] = useState({});
  const [formattObservations, setFormattObservations] = useState(null);

  useEffect(() => {
    (async function() {
      setElement(props.el);
      if (props.show) {
        //LOADER
        await getObservations(props.stage, props.el, setFormattObservations);
        //LOADER
      }
    })();
  }, [props.show]);

  return (
    <>
      <Modal show={props.show} size="lg" onHide={() => props.setShow(false)}>
        <Modal.Header>
          <Modal.Title>Observaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="observations">{formattObservations}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setShow(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ObservationModal;
