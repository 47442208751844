import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";
import { NewClassRequest } from "../classes/NewClassRequest";
import { getAllAlertNotifications } from "../alert_notification_table/alert_notification_logic";

export const initForm = {
  user: "",
  password: ""
};


// ###REFACTORIZADA
export const SignUp = async (form, props, notificationsContext) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/sign_in`,
    "post",
    null,
    {
      email: form.user,
      password: form.password,
      app_id: 2
    }
  );
  const isLogged = await request.executeRequest();

  if ( isLogged.code === 200 ) {

    if ( isLogged.response.error ) {

      NotificationManager.error( isLogged.response.message );
    }
    else {

      await setCookies(isLogged.completeResponse, props);
      const allNotifications = await getAllAlertNotifications( isLogged.response.user.id );
      if ( allNotifications.length > 0 ) {
        notificationsContext.updateCount( true, "sum", allNotifications.length );
        NotificationManager.warning( 
          allNotifications.length === 1 ? "Cuentas con una notificación pendiente" : `Cuentas con ${allNotifications.length} pendientes`
        );      
      }
    }
  }
  else {

    NotificationManager.error( isLogged.response.message );
  }
};

async function setCookies(response, props) {
  try {
    //HEADERS
    Cookies.set("token_02", response.headers["access-token"]);
    Cookies.set("uid_02", response.headers["uid"]);
    Cookies.set("client_02", response.headers["client"]);
    //USER
    Cookies.set("userId_02", response.data.data.user.id);
    Cookies.set("names_02", response.data.data.user.names);
    Cookies.set("avatar_02", response.data.data.user.avatar);
    Cookies.set(
      "paternal_surname_02",
      response.data.data.user.paternal_surname
    );
    Cookies.set("regime_02", response.data.data.user.regime);
    Cookies.set("isAuth_02", true);
    Cookies.set("authentication_token_02", response.data.data.jwt );

    //SI TODO ESTA BIEN MANDAMOS AL HOME
    props.history.push("/home");
  } catch (error) {
    // ERROR AL GUARDAR LAS COOKIES
    if (typeof error === "string" && error !== "")
      NotificationManager.error(error, "Error:");
  }
}

export function destroyCookies() {
  Cookies.remove("client_02", { path: "/" });
  Cookies.remove("uid_02", { path: "/" });
  Cookies.remove("token_02", { path: "/" }); // removed!
  Cookies.remove("userId_02", { path: "/" }); // removed!
  Cookies.remove("names_02", { path: "/" }); // removed!
  Cookies.remove("regime_02", { path: "/" }); // removed!
  Cookies.remove("paternal_surname_02", { path: "/" });
  Cookies.remove("isAuth_02", { path: "/" });
  Cookies.remove("avatar_02", { path: "/" });
  Cookies.remove("authentication_token_02", { path: "/" });
  Cookies.remove("app_config", { path:"/" } );
}
