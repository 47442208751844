import React from "react";
import { Alert } from "react-bootstrap";

const AlertFormValidation = ({ styleClassAlert, title, errors, visible }) => {
  const errorList = errors.map((el, index) => {
    if (typeof el.message === "object") {
      let getErrors = [];

      Object.keys(el.message).forEach(key => {
        getErrors.push(`${key} ${el.message[key]}`);
      });

      const errList = getErrors.map((err, i) => {
        return <li key={i}>{err}</li>;
      });
      return errList;
    }
    return <li key={index}>{el.message}</li>;
  });

  return (
    <div style={{ display: visible ? "block" : "none" }}>
      <Alert variant={styleClassAlert}>
        <Alert.Heading>{title}</Alert.Heading>
        {errorList}
        <hr />
      </Alert>
    </div>
  );
};

export default AlertFormValidation;
