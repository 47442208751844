import React from "react";
import Cookies from "js-cookie";
import PhysicalPerson from "../confirm_registration/PhysicalPerson";
import MoralPerson from "../confirm_registration/MoralPerson";
import {Container, Row, Col} from "react-bootstrap";
import OneColumn from "../layout/containers/OneColumn";

const EditProfile = props => {
  const regime = Cookies.get("regime_02");

  let pageConfig = {
      active: '',
      title: ''
  };

  if (regime === "1") {
    return (
        <OneColumn {...props} pageConfig={pageConfig} hideTopMenu={true} fluid={true} noTitle={true}>
            <Container fluid={true} className="bg-size-cover" style={{ minHeight: '76vh', backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)" }}>
                <Container>
                    <Row>
                        <Col sm={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className="mY-30">
                            <PhysicalPerson editProfile={true} history={props.history} />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </OneColumn>
    );
  } else if (regime === "2") {
    return (
        <OneColumn {...props} pageConfig={pageConfig} hideTopMenu={true} fluid={true} noTitle={true}>
            <Container fluid={true} className="bg-size-cover" style={{ minHeight: '76vh', backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)" }}>
                <Container>
                    <Row>
                        <Col sm={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className="mY-30">
                            <MoralPerson editProfile={true} history={props.history} />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </OneColumn>
    );
  }
};

export default EditProfile;
