/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import OneColumn from "../layout/containers/OneColumn";
import {Col, Grid, Row} from "react-flexbox-grid";
import {getAllAvaliableProces} from "../formalities/formalities_logic";
import LoaderContext from "../layout/shared/loader_context";
import {Button, Card, FormControl, InputGroup, OverlayTrigger, Pagination, Tooltip} from "react-bootstrap";
import Dotdotdot from "react-dotdotdot";
import notificationContext from '../alert_notification_table/notification_context';
import { printUserNotification } from "../alert_notification_table/alert_notification_logic";

const ProcedureList = props => {
    const Loader = useContext(LoaderContext);
    const [processes, setProcess] = useState([]);
    const [prevUrl, setPrevUrl] = useState("");
    const [nextUrl, setNextUrl] = useState("");
    const [currentPage, setCurrentPage] = useState(-1);
    const [firstUrl, setFirstUrl] = useState("");
    const [lastUrl, setLastUrl] = useState("");
    const [notProcessFoundMessage, setNotProcessFoundMessage] = useState("");
    const [search, setSearch] = useState("");
    const [totalProcesses, setTotalProcesses] = useState(0);
    const first = (((6 * currentPage) - 6) + 1);
    const second = ((6 * currentPage) > totalProcesses ? totalProcesses : (6 * currentPage));

    let pageConfig = {
        active: "all_formalities",
        title: "Trámites en Línea"
    };

    let notificationCtxt = useContext(notificationContext);
    useEffect(() => {
        if( notificationCtxt.count > 0 ){
        printUserNotification();
        }
    }, []);

    useEffect(() => {
        (async function () {
            try {
                Loader.show(true);
                const response = await getAllAvaliableProces();

                // update pagination buttons
                setPrevUrl(response.previous_url);
                setNextUrl(response.next_url);
                setCurrentPage(response.current_page);
                setFirstUrl(response.first_url);
                setLastUrl(response.last_url);
                setTotalProcesses(response.total);

                setProcess(response.processes);
                setNotProcessFoundMessage("No hay trámites disponibles");
                Loader.show(false);
            } catch (e) {
                setNotProcessFoundMessage("No hay trámites disponibles");
                console.log(e);
                Loader.show(false);
            }
        })();
    }, []);

    const renderTooltip = (props) => {
        return <Tooltip {...props} show={String(props.show)}>Manual de usuario del trámite</Tooltip>;
    }

    const changeProcessList = async url => {
        Loader.show(true);

        // get process
        const response = await getAllAvaliableProces(url);

        if (response.code == 200) {
            // update pagination buttons
            setPrevUrl(response.previous_url);
            setNextUrl(response.next_url);
            setCurrentPage(response.current_page);
            setFirstUrl(response.first_url);
            setLastUrl(response.last_url);
            setTotalProcesses(response.total);
            // set avaible process
            setProcess(response.processes);
        }

        Loader.show(false);
    };

    const items =
        processes.length > 0 ? (
            <>
                <br/><br/>
                <Row>
                    {processes.map((el, index) => {
                        return (
                            <Col key={index} xs={12} sm={6} lg={6} xl={4}>
                                <Card key={index} className="w-100 procedure-card mB-20">
                                    <Card.Header>
                                        <div className="text-container">
                                            <Dotdotdot clamp={2}>{el.name}</Dotdotdot>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="text-container">
                                            <Dotdotdot clamp={7} className="text-justify">
                                                {el.description}
                                            </Dotdotdot>
                                        </div>
                                    </Card.Body>
                                    <Card.Footer className="p-0">
                                        <div style={{display:"flex"}}>
                                            <Button
                                                variant="outline-primary"
                                                className="w-100 pY-10 font-weight-bolder"
                                                onClick={() => props.history.push(`/procedure/${el.id}`)}
                                            >
                                                Ir al trámite
                                            </Button>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 50, hide: 250 }}
                                                overlay={renderTooltip}
                                            >
                                                <a href={ `${el.manual_path}?token=${process.env.REACT_APP_TOKEN}`  } className={ el.manual_path ? "" : "d-none" } download>
                                                    <Button
                                                        className="secondary w-20 pY-10 font-weight-bolder"
                                                        style={{backgroundColor:"#bababa"}}
                                                    >
                                                        <i className="ti-agenda mr-1" />
                                                    </Button>
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </>
        ) : (
            <h3 className="text-center text-danger"> {notProcessFoundMessage} </h3>
        );

    const paginationToolBar = () => {
        if (firstUrl && lastUrl) {
            let isFirstUrl = firstUrl.includes("?page=" + currentPage);
            let isLastUrl = lastUrl.includes("?page=" + currentPage);

            return (
                <Pagination style={{justifyContent: "center"}}>
                    {typeof firstUrl == "string" && firstUrl !== "" ? (
                        isFirstUrl ? (
                            <Pagination.First disabled className="disable"/>
                        ) : (
                            <Pagination.First onClick={() => changeProcessList(firstUrl)}/>
                        )
                    ) : (
                        <Pagination.First disabled className="disable"/>
                    )}
                    {typeof prevUrl == "string" && prevUrl !== "" ? (
                        <Pagination.Item onClick={() => {changeProcessList(prevUrl);}}>{currentPage - 1}</Pagination.Item>
                    ) : (
                        ""
                    )}
                    {currentPage > 0 ? (
                        <Pagination.Item active>{currentPage}</Pagination.Item>
                    ) : (
                        ""
                    )}
                    {typeof nextUrl == "string" && nextUrl !== "" ? (
                        <Pagination.Item onClick={() => {changeProcessList(nextUrl);}}>{currentPage + 1}</Pagination.Item>
                    ) : (
                        ""
                    )}
                    {typeof lastUrl == "string" && lastUrl !== "" ? (
                        isLastUrl ? (
                            <Pagination.Last disabled className="disable"/>
                        ) : (
                            <Pagination.Last onClick={() => {changeProcessList(lastUrl);}}/>
                        )
                    ) : (
                        <Pagination.Last disabled className="disable"/>
                    )}
                </Pagination>
            );
        }
        return null;
    };

    const searchProcedureHandle = async () => {
        try {
            Loader.show(true);
            let response = search ? await getAllAvaliableProces(null, search) : await getAllAvaliableProces();

            setPrevUrl(response.previous_url);
            setNextUrl(response.next_url);
            setCurrentPage(response.current_page);
            setFirstUrl(response.first_url);
            setLastUrl(response.last_url);

            setProcess(response.processes ? response.processes : []);
            setNotProcessFoundMessage("No hay trámites disponibles");
            Loader.show(false);
        } catch (e) {
            setNotProcessFoundMessage("No hay trámites disponibles");
            console.log(e);
            Loader.show(false);
        }
    };

    const mouseOver = (e) => {
        e.target.style.backgroundColor = "#1d78ff";
        e.target.style.width = "120px";
    };
    
    const mouseOut = (e) => {
        e.target.style.backgroundColor = "#02cc98";
        e.target.style.width = "120px";
    };

    const handleKeyEnter = async (event) => {
        if (event.key === "Enter") await searchProcedureHandle()
        else if( event.key === "Backspace" && search.length === 1 ) {
            await clearSearch();
        }
    }

    const clearSearch = async () => {
        setSearch("");
        let inputSearcher = document.getElementById("searcher");
        inputSearcher.value = "";

        try {
            Loader.show(true);
            const response = await getAllAvaliableProces();

            setPrevUrl(response.previous_url);
            setNextUrl(response.next_url);
            setCurrentPage(response.current_page);
            setFirstUrl(response.first_url);
            setLastUrl(response.last_url);

            setProcess(response.processes ? response.processes : []);
            setNotProcessFoundMessage("No hay trámites disponibles");
            Loader.show(false);
        } catch (e) {
            setNotProcessFoundMessage("No hay trámites disponibles");
            console.log(e);
            Loader.show(false);
        }
    };

    return (
        <OneColumn {...props} pageConfig={pageConfig}>
            <Grid style={{minHeight: "62vh"}}>
                <Row>
                    <Col xs={12} sm={6}>
                        <h4 className="mB-30 mT-30 w-100">Listado de trámites</h4>
                    </Col>
                    <Col xs={12} sm={6}>
                        <InputGroup className="mT-30">
                            <FormControl
                                placeholder="Palabra o término a buscar "
                                aria-label="Palabra o término a buscar "
                                aria-describedby="basic-addon2"
                                onChange={(e)=>setSearch(e.target.value)}
                                onKeyDown={ search.length > 0 ? handleKeyEnter : null}
                                style={{fontSize: '1rem'}}
                                id="searcher"
                            />
                            <InputGroup.Append>
                                <Button 
                                    variant="outline-secondary" 
                                    onClick={searchProcedureHandle}
                                    onMouseOver={(e)=>mouseOver(e)} 
                                    onMouseOut={(e)=>mouseOut(e)}
                                    style={{width:"120px", transition:"width 0.2s", color: "#FAFAFA", backgroundColor:"#02cc98"}}
                                >
                                    <i className="ti-search" style={{pointerEvents:"none"}}/> Buscar
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <small>Escribe el término deseado y oprime el botón "Buscar"</small>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="w-100 mT-30">
                            A continuación, se enlistan los trámites disponibles bajo la modalidad online para la ciudadanía en general.
                        </span>
                        <br/>

                        {processes.length === 0 && totalProcesses === 0 ? '' : 
                            <div className='fl-r'>
                                <strong style={{ marginBottom:'10px' }}>
                                    Mostrando {first === second ? (' el # ' + second) : first + ' - ' + second} de un total de {totalProcesses} resultados
                                </strong> 
                            </div>
                        }
                        
                        {items}

                        {paginationToolBar()}
                    </Col>
                </Row>
            </Grid>
        </OneColumn>
    );
};

export default ProcedureList;
