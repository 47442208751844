import Cookies from "js-cookie";
import { destroyCookies } from "../login/login_logic";
import { NewClassRequest } from "../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";

export const initForm = {
  oldPassword: "",
  newPassword: "",
  passwordConfirm: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const changePassword = async form => {
  const { userId_02 } = Cookies.get();
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/users/change/password/${userId_02}`,
    "patch",
    null,
    {
      current_password: form.oldPassword,
      new_password: form.newPassword,
      password_confirmation: form.passwordConfirm
    }
  );
  const validateChangePassword = await request.executeRequest();

  if (
    validateChangePassword.code === 200 &&
    validateChangePassword.response && 
    validateChangePassword.response.error && 
    (validateChangePassword.response.code === 403 ||
    validateChangePassword.response.code === 401) &&
    validateChangePassword.response.message !== ''
  ) {
    NotificationManager.error(validateChangePassword.response.message);
  }
  else if (
    validateChangePassword.code === 200 &&
    validateChangePassword.response && 
    !validateChangePassword.response.error && 
    validateChangePassword.response.message !== ''
  ) {
    destroyCookies();
    setTimeout(() => {
      NotificationManager.success(
        validateChangePassword.response.message
      );
    }, 500);
  }
  else {
    NotificationManager.error('Ha sucedido un error desconocido, inténtalo nuevamente o contacta al administrador');
    return;
  }

};
/***********************************************************************************************************************************************/
