import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import { Row, Col } from "react-flexbox-grid";

import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const columns = [
  {
    dataField: "id",
    text: "Product ID",
    sort: true
  },
  {
    dataField: "name",
    text: "Product Name",
    sort: true
  },
  {
    dataField: "price",
    text: "Product Price",
    sort: true
  }
];

const products = [
  { id: 1, name: "product 1", price: 510 },
  { id: 2, name: "product 2", price: 44 },
  { id: 3, name: "product 3", price: 12300 },
  { id: 4, name: "product 4", price: 120 },
  { id: 5, name: "product 5", price: 4500 },
  { id: 6, name: "product 6", price: 1890 },
  { id: 7, name: "product 7", price: 1700 },
  { id: 8, name: "product 8", price: 1380 },
  { id: 9, name: "product 9", price: 1300 },
  { id: 10, name: "product 10", price: 2305 },
  { id: 11, name: "product 11", price: 2305 }
];

const { SearchBar } = Search;

const BoostrapTable = () => {
  return (
    <Row center="xs">
      <Col xs={12}>
        <ToolkitProvider keyField="id" data={products} columns={columns} search>
          {props => (
            <Row center="xs">
              <Col xs={8} style={{ padding: "20px" }}>
                <SearchBar
                  {...props.searchProps}
                  className="custome-search-field"
                  style={{ color: "black" }}
                  delay={1000}
                  placeholder="Dato a buscar"
                />
              </Col>
              <Col xs={12} sm={10}>
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={paginationFactory()}
                />
              </Col>
            </Row>
          )}
        </ToolkitProvider>
      </Col>
    </Row>
  );
};

export default BoostrapTable;
