import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { Request } from "../../classes/Request";

export const initValues = (props, setSignedData, setSignature) => {
  //CARGA VALORES INICIALES
  checkDefaultValues(props, setSignedData, setSignature);

  //INICIALIZAMOS LOS VALORES DE LA FIRMA
  initSettingsSignature(props);
};

export const sendSignatureServer = async (
  tabFirel,
  cer,
  key,
  password,
  firelPfx,
  passwordFirel,
  props,
  setSignature,
  signedData,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) => {
  //EVENTO CUANDO INTENTAN MANDAR LA INFORMACION POR  FIRMA FIEL
  if (!tabFirel) {
    if (cer !== null && key !== null && password !== "") {
      //VALIDA Y MANDA A LLAMAR LAS FUNCIONES DE LLAVES Y PKCS1
      window.firma.setEvidences(0);
      const referStringBase64 = utf8_to_b64(
        `${props.stage}|${props.el.name}|${Cookies.get("userId_02")}|${
          props.el.id
        }|${props.el.extra_attributes.document_id}`
      );
      window.firma.setReferencia(referStringBase64);
      validaLlavesCerKey(
        props,
        password,
        signedData,
        setSignature,
        setFinalDocumentUrlPDF,
        setLocalLoader,
        setShowDowloadDocument,
        showModalSignature,
        setSignInStep
      );
    } else if (cer === null && key === null) {
      setLocalLoader(false);
      NotificationManager.error(
        "Se necesitan los archivos .cer y .key para generar una firma digital."
      );
    } else if (password === "") {
      setLocalLoader(false);
      NotificationManager.error("Se necesita la llave para firmar documentos.");
    }
  } else {
    //EVENTO CUANDO INTENTAN MANDAR LA INFORMACION POR  FIRMA FIREL
    if (firelPfx !== null && passwordFirel !== "") {
      //VALIDA Y MANDA A LLAMAR LAS FUNCIONES DE VALIDAFPX Y PKCS1
      validateFPX(
        props,
        passwordFirel,
        signedData,
        setSignature,
        setFinalDocumentUrlPDF,
        setLocalLoader,
        setShowDowloadDocument,
        showModalSignature,
        setSignInStep
      );
    } else if (firelPfx === null) {
      setLocalLoader(false);
      NotificationManager.error(
        "Se necesita el archivo .fpx para  generar una firma digital."
      );
    } else if (passwordFirel === "") {
      setLocalLoader(false);
      NotificationManager.error(
        "Se necesita la contraseña para generar la firma."
      );
    }
  }
};

/***************************************VALIDATE KEYS**************************************** */
function validaLlavesCerKey(
  props,
  password,
  signedData,
  setSignature,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  //VALIDA QUE EL CER Y KEY SEAN LEGITIMOS Y MANDA LLAMAR EL FIRMADO.
  try {
    window.firma.validateKeyPairs(password, function(objResult) {
      if (objResult.state === 0) {
        window.firma.setEvidences(0);
        const referStringBase64 = utf8_to_b64(
          `${props.stage}|${props.el.name}|${Cookies.get("userId_02")}|${
            props.el.id
          }|${props.el.extra_attributes.document_id}`
        );
        window.firma.setReferencia(referStringBase64);
        if (props.isDocument) {
          //SI ESTA FIRMANDO EL DOCUMENTO EJECUTARA ESTE METODO
          const base64String = utf8_to_b64(props.htmlIntoBase64);
          const encodedString = new Buffer(props.htmlIntoBase64).toString(
            "base64"
          );
          const file = convertStringIntoPdf(encodedString);
          signBothPKCS(
            file,
            props.htmlIntoBase64,
            base64String,
            setFinalDocumentUrlPDF,
            setLocalLoader,
            setShowDowloadDocument,
            showModalSignature,
            setSignInStep
          );
          //pkcs1AndDigest(base64String, file, props.htmlIntoBase64);
          //signPkCS7(file, props.htmlIntoBase64);
        } else {
          signPKCS1(signedData, setSignature);
        }
      } else {
        setLocalLoader(false);
        NotificationManager.error(objResult.description);
      }
    });
  } catch (error) {
    console.log(error);
  }
}

function validateFPX(
  props,
  passwordFirel,
  signedData,
  setSignature,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  try {
    window.firma.openPfx(passwordFirel, function(objResult) {
      if (objResult.state === 0) {
        window.firma.setEvidences(0);
        const referStringBase64 = utf8_to_b64(
          `${props.stage}|${props.el.name}|${Cookies.get("userId_02")}|${
            props.el.id
          }|${props.el.extra_attributes.document_id}`
        );
        window.firma.setReferencia(referStringBase64);
        if (props.isDocument) {
          //SI ESTA FIRMANDO EL DOCUMENTO EJECUTARA ESTE METODO
          const base64String = utf8_to_b64(props.htmlIntoBase64);
          const encodedString = new Buffer(props.htmlIntoBase64).toString(
            "base64"
          );
          const file = convertStringIntoPdf(encodedString);
          signBothPKCS(
            file,
            props.htmlIntoBase64,
            base64String,
            setFinalDocumentUrlPDF,
            setLocalLoader,
            setShowDowloadDocument,
            showModalSignature,
            setSignInStep
          );
          //pkcs1AndDigest(base64String, file, props.htmlIntoBase64);
          //signPkCS7(file, props.htmlIntoBase64);
        } else {
          signPKCS1(signedData, setSignature);
        }
      } else {
        setLocalLoader(false);
        NotificationManager.error(objResult.description);
      }
    });
  } catch (error) {
    console.log(error);
  }
}
/******************************************************************************************** */

function convertStringIntoPdf(encodedString) {
  //FUNCION QUE CONVIERTE EL HTML EN UN FILE PDF
  const byteCharacters = atob(encodedString);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  let blob = new Blob([byteArray], { type: "application/pdf" });
  let file = new File([blob], "signature.pdf");
  return file;
}

async function signPkCS7(
  file,
  html,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  window.firma.signPKCS7(
    file,
    1024,
    window.fielnet.Digest.SHA1,
    {
      tsa: {
        name: "tsaName",
        algorithm: window.fielnet.Digest.SHA1
      },
      nom: {
        name: "noName"
      }
    },
    function(objResult) {
      if (objResult.state === 0) {
        finishFirmDocument(
          html,
          objResult.signDocument,
          objResult.signVector,
          setFinalDocumentUrlPDF,
          setLocalLoader,
          setShowDowloadDocument,
          showModalSignature,
          setSignInStep
        );
      } else {
        setLocalLoader(false);
        //Detalles del error ocurrido
        //NotificationManager.error(objResult.data.messages[0].error);
      }
    }
  );
}

/***********************************INIT FUNCTIONS******************************************* */
function checkDefaultValues(props, setSignedData, setSignature) {
  //FUNCION QUE SETEA LOS VALORES EN LOS CAMPOS CUANDO YA TIENEN DATOS
  if (props.isDocument) {
  } else {
    if (props.el.value !== "") {
      setSignature(props.el.value);
    }
    props.el.extra_attributes.form.inputs.forEach(el => {
      if (el.name === "signed_data") {
        setSignedData(el.value);
      }
    });
  }
}

function initSettingsSignature(props) {
  //VALIDA E INICIALIZA LA FUNCIONALIDAD DE LA FIRMA..
  if (window.firma.validateWebBrowser()) {
    //INIT READ INPUT FILES
    window.firma.readCertificateAndPrivateKey(
      `${props.el.name}_cerFiel`,
      `${props.el.name}_keyFiel`
    );
    window.firma.readPfx(`${props.el.name}_pfxFirel`);
  } else {
    NotificationManager.error(
      "El navegador no es compatible para la funcionalidad de firma."
    );
  }
}
/*******************************************************************************************/

function signPKCS1(signedData, setSignature) {
  //FUNCION QUE HACE EL FIRMADO PKCS1, PARAMS TEXTO A FIRMAR Y FUNCION QUE SETEA FIRMA EN CAMPO
  window.firma.signPKCS1(
    signedData,
    window.fielnet.Digest.SHA1,
    window.fielnet.Encoding.UTF8,
    function(res) {
      if (res.state === "0") {
        setSignature(res.sign);
      } else {
        NotificationManager.error(
          "Ocurrio un error a la hora de realizar el firmado"
        );
      }
    }
  );
}

function signBothPKCS(
  file,
  html,
  htmlBase64,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  window.firma.signPKCS1(
    htmlBase64,
    window.fielnet.Digest.SHA1,
    window.fielnet.Encoding.UTF8,
    function(res) {
      if (res.state === "0") {
        signPkCS7(
          file,
          html,
          setFinalDocumentUrlPDF,
          setLocalLoader,
          setShowDowloadDocument,
          showModalSignature,
          setSignInStep
        );
      } else {
        setLocalLoader(false);
        NotificationManager.error(
          "Ocurrio un error a la hora de realizar el firmado"
        );
      }
    }
  );
}

function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}
/* eslint-disable */
function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

//FINISH DOCUMENT
async function finishFirmDocument(
  html,
  document,
  vector,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/signer_document?token=${process.env.REACT_APP_TOKEN}`,
    "post",
    null,
    {
      data: html,
      signDocument: document,
      signVector: vector
    }
  );
  const finishFirmDocumentData = await request.executeRequest();
  if (finishFirmDocumentData.code === 200) {
    setFinalDocumentUrlPDF(finishFirmDocumentData.data.data.data.url);
    setLocalLoader(false);
    setShowDowloadDocument(true);
    showModalSignature(false);
    setSignInStep(true);
    // MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setTimeout(() => {
      NotificationManager.success(
        "El firmado de documento se realizo correctamente."
      );
    }, 500);
  } else {
    setLocalLoader(false);
    showModalSignature(false);
  }
}

/****************************************************************************************** */

/* const formatBase64 = string => {
  const formatted = _.split(string, "base64,", 2);
  return formatted[1];
}; */
// function pkcs1AndDigest(bas64Document, file, html) {
//   window.firma.signPKCS1(
//     bas64Document,
//     window.fielnet.Digest.SHA1,
//     window.fielnet.Encoding.UTF8,
//     function(res) {
//       if (res.state === "0") {
//         console.log("pqcs1");
//         getFileDigest(file, html);
//       } else {
//         NotificationManager.error(
//           "Ocurrio un error a la hora de realizar el firmado"
//         );
//       }
//     }
//   );
// }
//Primero pksc1 , le pasamos como parametro firmar el base64 del documento.
//digest.

// function getFileDigest(file, html) {
//   window.firma.getFileDigest(
//     file,
//     10000,
//     window.fielnet.Digest.SHA1,
//     function(data) {
//       let digest = data;
//       console.log("obtener digest");
//       signFileDigest(file, digest, html);
//     },
//     function(e) {
//       alert(e);
//     }
//   );
// }

// function signFileDigest(file, digest, html) {
//   window.firma.signFileDigest(
//     window.fielnet.Digest.Source.USER,
//     digest,
//     window.fielnet.Format.B64,
//     window.fielnet.Digest.SHa1,
//     {
//       tsa: {
//         name: "tsasvi",
//         algorithm: window.fielnet.Digest.SHA1
//       },
//       nom: { name: "NA" }
//     },
//     function(data) {
//       console.log(data);
//       //finishFirmDocument(html);
//       signPkCS7(file, data.signDocument, data.signVector);
//     },
//     function(e) {
//       alert(e);
//     }
//   );
// }

// Uso:

// window.firma.decodeCertificate(
//   true,
//   {
//     tsa: {
//       name: "tsaName",
//       algorithm: window.fielnet.Digest.SHA1
//     }
//   },
//   function(e) {
//     console.log("Estoy decodificanco el certificado");
//     console.log(e);
//   }
// );

/* export const validateSiganture = async (
  key,
  cer,
  password,
  form_id,
  stage,
  url,
  name,
  label
) => {
  const formattKey = await formatBase64(key);
  const formattCer = await formatBase64(cer);
  const request = new Request(url, "post", null, {
    cer_file: formattCer,
    key_file: formattKey,
    password,
    token: process.env.REACT_APP_TOKEN,
    name,
    stage_id: stage,
    form_id,
    key: label
  });
  const validateSigantureData = await request.executeRequest();
  if (validateSigantureData.code === 200) {
    NotificationManager.success(
      "El proceso de firmado se realizo correctamente",
      "Firma ejecutada correctamente"
    );
    return validateSigantureData.data.data.data.signature;
  } else if (
    typeof validateSigantureData.data.data.data.messages != "undefined"
  ) {
    const errors = validateSigantureData.data.data.data.messages.map(
      (error, index) => {
        if (error.field) {
          return <li key={index}>{`${error.field} : ${error.error}`}</li>;
        } else {
          return <li key={index}>{error.error}</li>;
        }
      }
    );
    NotificationManager.error(
      errors,
      "No se pudo realizar el firmado ya que cuenta con estos errores."
    );
    return null;
  } else {
    return null;
  }
}; */

/*  window.firma.validateKeyPairs(password, function(objResult) {
          console.log(objResult);
          if (objResult.state === 0) {
            window.firma.decodeCertificate(
              true,
              {
                tsa: {
                  name: "tsaName",
                  algorithm: window.fielnet.Digest.SHA1
                }
              },
              function(e) {
                console.log(e);
              }
            );
          } else {
            NotificationManager.error(objResult.description);
          }
        }); */

////////////////YA VERIFICA LA FIRMA

/* if (objResult.state === 0) {
          //Par de llaves validadas correctamente
          window.firma.decodeCertificate(
            true,
            {
              tsa: {
                name: "tsaName",
                algorithm: window.fielnet.Digest.SHA1
              }
            },
            function(e) {
              console.log(e);
            }
          );
        } else {
          NotificationManager.error(objResult.description);
        } */

// window.firma.signFileDigest(
//   window.fielnet.Digest.Source.USER,
//   "lfa4ClHOLH7ctfKq+C+awRoQU3M=",
//   window.fielnet.Format.B64,
//   window.fielnet.Digest.SHa1,
//   {
//     tsa: {
//       name: "tsasvi",
//       algorithm: window.fielnet.Digest.SHA1
//     },
//     nom: { name: "NA" }
//   },
//   function(data) {
//     console.log(data);
//   }
// );
// window.firma.signFileDigest(
//   window.fielnet.Digest.Source.USER,
//   "95f6b80a51ce2c7edcb5f2aaf82f9ac11a105373",
//   window.fielnet.Format.HEX,
//   window.fielnet.Digest.SHa1,
//   {
//     tsa: {
//       name: "tsasvi",
//       algorithm: window.fielnet.Digest.SHA1
//     },
//     nom: { name: "NA" }
//   },
//   function(data) {
//     console.log(data);
//   }
// );
//SI ESTA FIRMANDO EL DOCUMENTO EJECUTARA ESTE METODO
// const encodedString = new Buffer(props.htmlIntoBase64).toString(
//   "base64"
// );
// const file = convertStringIntoPdf(encodedString);
//signPkCS7(file);
