import React, { useState, useEffect } from "react";
import { Grid, Col, Row } from "react-flexbox-grid";
import { Link } from 'react-router-dom';
import { Image, Modal } from "react-bootstrap";
import { getFooterData, initStateFooter, privacyRequest } from "./footer_logic";
import Cookies from "js-cookie";

const Footer = () => {
  const [initForm, setInitForm] = useState(initStateFooter);
  const [showModal, setShowModal] = useState(false);
  const [privacyModalElements, setPrivacyModalElements] = useState({});
  const { app_config } = Cookies.get();

  useEffect(() => {
    (async function() {
      if ( !app_config ) {
        await getFooterData(setInitForm);
      }
      else {
        setInitForm( JSON.parse( app_config ) );
      }
    })();
  }, []);

  const getDataPrivacy = async () => {

    await privacyRequest( setPrivacyModalElements, setShowModal );
  };

  return (
    <>
      <footer>
        <Grid className="pY-10" fluid={true} style={{backgroundColor:'#5c5c5c', color:'#fff', fontWeight:'bold'}}>
          <Row center="xs" middle="xs">
            <Col xs={6}>
              <span>
                Consulta nuestro{" "}
                <span 
                  onClick={getDataPrivacy}
                  className="cursor-pointer px-1"
                  style={{color:'#10e9c3', textDecoration:'underline'}}
                >
                  aviso de privacidad
                </span>{" "}
                vigente
              </span>
            </Col>
            <Col xs={6}>
              <img 
                style={{ height:'150px'}}
                src={process.env.PUBLIC_URL + "/img/footer_jalisco_image.png"} 
              />
            </Col>
          </Row>
        </Grid>
      </footer>
      <>
          <Modal
            size="lg"
            show={showModal}
            onHide={() => setShowModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            {privacyModalElements}
          </Modal>
        </>
    </>
  );
};

export default Footer;
