import React from "react";
import { NewClassRequest } from "../../classes/NewClassRequest"
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";
import { Button, Form } from "react-bootstrap";
import { renderErrorsByInputName } from "../../search_for_lawyers/search_lawyers_logic";
import Dropzone from "react-dropzone";
import Select from "react-select";
import TableAddProofs from "../../helpers_components/TableAddProofs";

export const columns = [
  {
    dataField: "actions",
    text: "Documentos",
    sort: true,
    style: { maxWidth: "250px", minWidth: "120px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "time_indicator",
    text: "Indicador",
    sort: true,
    style: { maxWidth: "250px", minWidth: "120px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "filename",
    text: "Nombre",
    sort: true,
    style: { maxWidth: "250px", minWidth: "100px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "alias",
    text: "Etiqueta",
    sort: true,
    style: { maxWidth: "250px", minWidth: "100px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "document_type_translated",
    text: "Tipo",
    sort: true,
    style: { maxWidth: "250px", minWidth: "100px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "created_date",
    text: "Creación",
    sort: true,
    style: { maxWidth: "80%", minWidth: "100px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "comment",
    text: "Comentarios",
    sort: true,
    style: { maxWidth: "250px", minWidth: "100px", textAlign: "justify" },
    headerStyle: { textAlign: "center" }
  }
];

export const getDocumentsByExpedientNumber = async (
  expedient_number,
  setDataExpedient,
  setDataDocuments,
  setTextNotDataDocuments,
  setTotalDocuments,
  sizePerPage,
  page,
  loader,
  setStateDocuments
) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/documents/${expedient_number}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseDocumentsExpedient = await request.executeRequest();
  if (responseDocumentsExpedient.code === 200) {

    if (responseDocumentsExpedient.response.expedient && responseDocumentsExpedient.response.expedient) {

      const documents = await formattedDocuments(
        responseDocumentsExpedient.response,
        loader,
        setStateDocuments
      );
      const expedient = {
        ...responseDocumentsExpedient.response.expedient,
        date: responseDocumentsExpedient.response.date ? new Date(responseDocumentsExpedient.response.date).toLocaleDateString('es-MX', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        }) : null
      };
      setDataExpedient(expedient);
      setDataDocuments(documents);
      setTotalDocuments(responseDocumentsExpedient.response.total);
      setTextNotDataDocuments(
        responseDocumentsExpedient.response.documents.length > 0 ?
          "" :
          responseDocumentsExpedient.response.message
      );
    }
  }
};

const formattedDocuments = (dataResponse, loader, setStateDocuments) => {
  let { expedient, documents } = dataResponse;
  const userMatch = String(expedient['user_id']) === Cookies.get('userId_02');
  let doc_type = 'Acuerdo';

  return documents.map((document, index) => {

    switch (document["document_type"]) {
      case "agreement":
        doc_type = 'Acuerdo';
        break;

      case "promotion":
        doc_type = 'Promoción';
        break;

      case "judgment":
        doc_type = 'Sentencia';
        break;

      default:
        break;
    }
    const timeIndicator = checkExpiredTime(document.expired);

    document["document_type_translated"] = doc_type;
    document["created_date"] = new Date(document["created_at"].replace(/-/g, "/")).toLocaleDateString('es-MX', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
    document["actions"] = (
      <div className="procedures-actions">
        <button
          onClick={userMatch ? () => downloadFile(document["file_path"], loader) : null}
          className={
            userMatch ?
              "btn btn-info" :
              "btn btn-secondary no-event-click"
          }
          title="Escrito de la promoción"
        >
          <i className="ti-notepad" />
        </button>
        <button
          //onClick={userMatch ? () => downloadFile(document["evidence_path"], loader) : null} 
          onClick={userMatch ? () => setStateDocuments(document['id']) : null}
          className={
            document["evidence_path"] && userMatch ?
              "btn button-see-evidence-documents mL-10" :
              document["evidence_path"] && !userMatch ?
                "btn btn-secondary mL-10 no-event-click" :
                "d-n"
          }
          title="Documento de pruebas"
        >
          <i className="ti-files" />
        </button>
      </div>
    );
    document['time_indicator'] = (<span className={"dot-" + timeIndicator} />);

    return document;
  });
};

export const downloadFile = (urlFile, loader) => {
  loader.show(true);
  let a = document.createElement('a');
  a.href = urlFile;
  a.download = true;
  a.target = "_blank";
  a.rel = "noopener noreferrer";

  a.click();
  loader.show(false);
};

const checkExpiredTime = (dataExpired) => {
  if (dataExpired.expired_at && dataExpired.warning && !dataExpired.answered_at) {
    const expiredDate = new Date(dataExpired.expired_at);
    const todayDate = new Date();
    const expired = new Date(
      expiredDate.getFullYear(),
      expiredDate.getMonth(),
      expiredDate.getDate()
    );
    const today = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate()
    );
    const timeTranscurred = (expired.getTime() - today.getTime()) / 86400000;
    if (timeTranscurred >= dataExpired.warning) return 'green';
    if (timeTranscurred < dataExpired.warning && timeTranscurred >= 0) return 'yellow';
    if (timeTranscurred < 0) return 'red';
    return 'white';
  } else return 'white';
};

export const getEvidencesDocument = async (
  documentId,
  setDocumentsData,
  setShowDocuments
) => {

  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/evidences/retrieve/${documentId}`,
    "get",
    null,
    {}
  );

  const responseEvidences = await request.executeRequest();
  if (
    responseEvidences.code === 200 &&
    responseEvidences.response &&
    responseEvidences.response.evidences
  ) {
    const { evidences } = responseEvidences.response;
    setDocumentsData(evidences);
    setShowDocuments(true);
  }
  else {
    setDocumentsData([]);
    setShowDocuments(true);
    NotificationManager.info(
      'No se encontraron anexos en esta promoción',
      '',
      3500
    );
  }
};

export const bodyModal = (
  setErrors, setPromotion, errors, promotion,
  imageURL, onDrop, typePromotion, expedientNumber,
  reloadComponent, dataUploadEvidences
) => {
  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Label>* Etiqueta:</Form.Label>
          <Form.Control
            onChange={(e) => {
              setErrors({ ...errors, alias: [] });
              setPromotion({ ...promotion, alias: e.target.value });
            }}
            placeholder="Agrega una etiqueta para identificar esta promoción"
            className={errors["alias"] ? "is-invalid border-error" : ""}
          />
          {renderErrorsByInputName(errors, "alias")}
          <Form.Text className={errors["alias"] ? "d-n" : "text-muted"}>
            Ingresa una etiqueta para identificar esta promoción
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>Comentarios:</Form.Label>
          <Form.Control
            onChange={(e) => setPromotion({ ...promotion, comment: e.target.value })}
            as="textarea"
            rows="5"
            aria-label="Comentarios"
            maxLength={255}
            placeholder="Agrega algún comentario respecto a esta promoción"
            className={errors["comment"] ? "is-invalid border-error" : ""}
          />
          {renderErrorsByInputName(errors, "comment")}
          <Form.Text className={errors["comment"] ? "d-n" : "text-muted"}>
            Ingresa algún comentario respecto a esta promoción
          </Form.Text>
        </Form.Group>

        <>
          <Form.Group controlId="formBasicFile">
            <Form.Label>* Documento:</Form.Label>
            <Dropzone
              onDrop={(files, element) => onDrop(files, 1)}
              accept={[".png", ".pdf", ".jpg", ".jpeg"]}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="file-nilo-wrapper" {...getRootProps()}>
                  { '* Selecciona un documento'}
                  <input style={{ display: "inline!important" }} className="form-control" {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </Form.Group>
          {renderErrorsByInputName(errors, "file")}
          <div
            className={typePromotion["type"] ? "file-upload-wrapper" : "d-none"}
          >
            <img
              className={typePromotion["type"] === "document" ? "d-block" : "d-none"}
              src={process.env.PUBLIC_URL + "/img/document.svg"}
              alt="Documento"
            />
            <img
              className={typePromotion["type"] === "image" ? "" : "d-none"}
              src={imageURL}
              alt="Imagen"
              width="50%"
            />
          </div>
          <small style={{ float: "right" }}>{typePromotion["name"]}</small>
          <br />
        </>

        <Form.Group>
          <br />
          <Form.Label>* Nombre del promovente:</Form.Label>
          <Form.Control
            value={promotion["promoter"] ? promotion["promoter"] : ""}
            aria-label="Nombre del promovente"
            onChange={(e) => {
              setPromotion({ ...promotion, promoter: e.target.value });
              setErrors({ ...errors, promoter: [] });
            }}
            type="text"
            className={errors["promoter"] ? "is-invalid border-error" : ""}
          />
          {renderErrorsByInputName(errors, "promoter")}
        </Form.Group>

        <Form.Group >
          <Form.Label>* Número de copias del escrito:</Form.Label>
          <Form.Control
            value={promotion["amount_copies"] ? promotion["amount_copies"] : ""}
            aria-label="Número de copias del escrito"
            onChange={(e) => {
              setPromotion({ ...promotion, amount_copies: e.target.value });
              setErrors({ ...errors, amount_copies: [] });
            }}
            type="number"
            min={0}
            onInput={(e) => {
              if (e.target.value < 0) e.target.value = 0;
            }}
            className={errors["amount_copies"] ? "is-invalid border-error" : ""}
          />
          {renderErrorsByInputName(errors, "amount_copies")}
        </Form.Group>

        <Form.Group >
          <Form.Label>* Número de firmas:</Form.Label>
          <Form.Control
            value={promotion["amount_signatures"] ? promotion["amount_signatures"] : ""}
            aria-label="Número de firmas"
            onChange={(e) => {
              setPromotion({ ...promotion, amount_signatures: e.target.value });
              setErrors({ ...errors, amount_signatures: [] });
            }}
            type="number"
            min={0}
            onInput={(e) => {
              if (e.target.value < 0) e.target.value = 0;
            }}
            className={errors["amount_signatures"] ? "is-invalid border-error" : ""}
          />
          {renderErrorsByInputName(errors, "amount_signatures")}
        </Form.Group>

        <Form.Group >
          <Form.Label>* ¿Quieres agregar anexos?</Form.Label>
          <Form.Check
            type="radio"
            label={"Si"}
            checked={promotion['hasProofs'] === "si" ? true : false}
            id={"si"}
            value="si"
            onChange={(e) => {
              setPromotion({ ...promotion, hasProofs: e.target.value });
            }}
          />
          <Form.Check
            type="radio"
            label={"No"}
            checked={promotion['hasProofs'] === "no" ? true : false}
            id={"no"}
            value="no"
            onChange={(e) => {
              setPromotion({ ...promotion, hasProofs: e.target.value });
            }}
          />
          {renderErrorsByInputName(errors, "hasProofs")}
        </Form.Group>

        {
          promotion['hasProofs'] === 'si' ?
            <>
              <Form.Group controlId="formBasicFile">
                <Form.Label>* Anexos</Form.Label>
                <TableAddProofs
                  acceptFiles={[".png", ".pdf", ".jpg", ".jpeg"]}
                  getFiles={(files) => {
                    if (files.length > 0) {
                      setPromotion({
                        ...promotion,
                        evidence_file: files
                      });
                      setErrors({
                        ...errors,
                        evidence_file: []
                      });
                    }
                    else {
                      delete promotion['evidence_file'];
                    }
                  }}
                  reloadDataComponent={reloadComponent}
                  dataUploadEvidences={dataUploadEvidences}
                  expedientNumber={expedientNumber}
                  errorsHeredated={errors}
                />
              </Form.Group>
            </> : ""
        }
      </Form>
    </div>
  );
};

export const footerModal = (setCloseModal, handleSubmitPromotion) => {
  return (
    <>
      <Button variant="secondary" onClick={() => setCloseModal()}>Cancelar</Button>
      <Button variant="primary" onClick={() => handleSubmitPromotion()} >Guardar Cambios</Button>
    </>
  )
};

export const selectStylesDefault = {
  placeholder: (provided, state) => ({
    ...provided,
    color: '#c1c1c1'
  }),
  control: (provided, state) => {
    if (state.isDisabled) {
      return {
        ...provided,
        color: '#495057',
        backgroundColor: '#e9ecef',
        border: '1px solid #c1c1c1'
      };
    }
    else return {...provided}
  },
  singleValue: (provided, state) => {
    if (state.isDisabled) {
      return {
        ...provided,
        color: '#495057'
      };
    }
    else return {...provided}  }
};

export const optionsDocument = [
  {
    value: 'Copia simple',
    label: 'Copia simple'
  },
  {
    value: 'Copia certificada',
    label: 'Copia certificada'
  },
  {
    value: 'Original',
    label: 'Original'
  }
];

export const bodyModalAddEvidenceFile = (
  setFileState, fileState, getRootProps,
  errors, setErrors, getInputProps
) => {

  return (
    <div>
      <Form>

        <Form.Group>
          <Form.Label>* Tipo de documento:</Form.Label>
          <Select
            options={optionsDocument}
            onChange={async (selected) => {

              setFileState({ ...fileState, evidence_type: selected["value"] });
              setErrors({ ...errors, evidence_type: [] });
            }}
            placeholder="Selecciona el tipo de documento"
            styles={selectStylesDefault}
          />
          {renderErrorsByInputName(errors, "evidence_type")}
        </Form.Group>

        <Form.Group>
          <Form.Label>* Etiqueta:</Form.Label>
          <Form.Control
            onChange={(e) => {
              setErrors({ ...errors, alias: [] });
              setFileState({ ...fileState, alias: e.target.value });
            }}
            placeholder="Agrega una etiqueta para identificar este documento"
          />
          {renderErrorsByInputName(errors, "alias")}
        </Form.Group>

        <Form.Group>
          <Form.Label>* Anexo:</Form.Label>

          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Button style={{ fontWeight: "bold", fontSize: "14px" }} variant="primary" size="md" block>
                * Selecciona el anexo a subir
              </Button>
            </div>
          </section>
          {renderErrorsByInputName(errors, "evidence_file")}

          {
            fileState["evidence_file"] && fileState["evidence_file"]["preview"] ?
              <>
                <div
                  className={fileState["evidence_file"] && fileState["evidence_file"]["preview"] ? "container-evidence-file" : "d-none"}
                >
                  <img
                    src={fileState["evidence_file"]["preview"]}
                    alt="Imagen"
                    width="50%"
                  />
                </div>
                <small style={{ float: "right" }}>{fileState["evidence_file"]["name"]}</small>
                <br />
              </> :
              ""
          }
        </Form.Group>

      </Form>
    </div>
  );
};

export const getSearchDocumentByExpedientNumber = async (
  expedient_number,
  searchValue,
  setDataExpedient,
  setDataDocuments,
  setTextNotDataDocuments,
  setTotalDocuments,
  sizePerPage,
  page,
  loader,
  setStateDocuments
) => {

  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/search/documents/${searchValue}/${expedient_number}/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseDocumentsExpedient = await request.executeRequest();
  if ( responseDocumentsExpedient.code === 200 ) {
    if ( responseDocumentsExpedient.response.expedient && responseDocumentsExpedient.response.expedient ) {

      const documents = await formattedDocuments(
        responseDocumentsExpedient.response,
        loader,
        setStateDocuments
      );
      const expedient = {
        ...responseDocumentsExpedient.response.expedient,
        date: responseDocumentsExpedient.response.date ? new Date(responseDocumentsExpedient.response.date).toLocaleDateString('es-MX', {
          day:'numeric',
          month:'long',
          year:'numeric'
        }) : null
      };

      setDataExpedient(expedient);
      setDataDocuments(documents);
      setTotalDocuments(responseDocumentsExpedient.response.total);
      setTextNotDataDocuments( 
        responseDocumentsExpedient.response.documents.length > 0 ? 
        "" : 
        responseDocumentsExpedient.response.message 
      );
    }
  }
};