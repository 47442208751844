import React, { useState, useEffect, useContext } from "react";
import OneColumn from "../../layout/containers/OneColumn";
import { Container, Row, Button, Card } from "react-bootstrap";
import ExpedientsSearcher from "../expedient_searcher/ExpedientsSearcher";
import { Grid } from "react-flexbox-grid";
import { RemotePagination } from "../../helpers_components/remote_pagination/RemotePagination";
import {
  columns, getDocumentsByExpedientNumber, getEvidencesDocument, 
  getSearchDocumentByExpedientNumber, bodyModal, footerModal
} from "./expedient_details_logic";
import LoaderContext from "../../layout/shared/loader_context";
import ModalComponent from "../../helpers_components/ModalComponent";
import { NotificationManager } from "react-notifications";
import { fieldsValidation } from "../../search_for_lawyers/search_lawyers_logic";

let pageConfig = {
  active: '',
  title: ''
};

const ExpedientDetails = (props) => {
  const [cleaning, setCleaning] = useState(false);
  const [searching, setSearching] = useState(false);
  const loader = useContext(LoaderContext);
  const [dataDocuments, setDataDocuments] = useState([]);
  const [textNotDataDocuments, setTextNotDataDocuments] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [dataExpedient, setDataExpedient] = useState({});
  const expedientNumber = props.match.params.expedient;
  const [documentsState, setDocumentsData] = useState([]);
  const [showDocuments, setShowDocuments] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [promotion, setPromotion] = useState({});
  const [imageURL, setImageURL] = useState("");
  const [typePromotion, setTypePromotion] = useState({});
  const [dataUploadEvidences, setDataUploadEvidences] = useState(null);


  useEffect(() => {
    (async function () {
      loader.show(true);
      await getDocumentsByExpedientNumber(
        expedientNumber,
        setDataExpedient,
        setDataDocuments,
        setTextNotDataDocuments,
        setTotalDocuments,
        sizePerPage,
        page,
        loader,
        setStateDocuments
      );
      loader.show(false);
    })();
  }, []);

  const setStateDocuments = async (documentId) => {
    loader.show(true);
    await getEvidencesDocument(
      documentId,
      setDocumentsData,
      setShowDocuments
    );
    loader.show(false);
  };

  const searchDocuments = async () => {
    if ( search.length > 0 && /^$|.*\S+.*/.test(search) ) { 
      setSearching(true);
      loader.show(true);
      let searchValue = search.replace(/^\s+|\s+$/gm,'').replace('/','-');
      await getSearchDocumentByExpedientNumber(
        expedientNumber,
        searchValue,
        setDataExpedient,
        setDataDocuments,
        setTextNotDataDocuments,
        setTotalDocuments,
        sizePerPage,
        page,
        loader,
        setStateDocuments
      );
      setSearching(false);
      loader.show(false);
    } else {
      cleanSearchDocuments();
    }
  };

  const cleanSearchDocuments = async () => {
    setSearch('');
    setCleaning(true);
    loader.show(true);
    await getDocumentsByExpedientNumber(
      expedientNumber,
      setDataExpedient,
      setDataDocuments,
      setTextNotDataDocuments,
      setTotalDocuments,
      sizePerPage,
      page,
      loader,
      setStateDocuments
    );
    setCleaning(false);
    loader.show(false);
  };

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
  };

  return (
    <OneColumn {...props} pageConfig={pageConfig} hideTopMenu={true} fluid={true} noTitle={true}>
      <Container fluid={true} className="bg-size-cover" style={{ minHeight: '76vh' }}>
        <Container>
          <div className="mT-10">
            <div className="text-center">
              <h3>
                {dataExpedient['expedient_number']}
              </h3>
            </div>
            <div>
              <Button
                onClick={() => props.history.goBack()}
              >
                <i className="fas fa-arrow-left" /> Volver
            </Button>
            </div>
          </div>
          <Row style={{ justifyContent: "center" }}>
            <Card
              className="cardExpedientDetails"
              id="coverPDF"
            >
              <Card.Body>
                <Container>
                  <div>
                    <h5
                      style={{ color: 'gray', fontWeight: 'bold', marginTop: '20px', textAlign: 'center' }}
                    >
                      Datos generales del Expediente:
                    </h5>
                    <div
                      style={{ border: '0px solid rgba(0,0,0,.0625)', borderRadius: '4px' }}
                      className="table-responsive"
                    >
                      <table className="table table-sm table-bordered">
                        <tbody>
                          <tr>
                            <th className="caratule_heading pR-15">Region:</th>
                            <td className="caratule_content pL-15">{dataExpedient['kind_judgment'] || 'N/A'}</td>
                          </tr>

                          <tr>
                            <th className="caratule_heading pR-15">Tipo de materia:</th>
                            <td className="caratule_content pL-15">{dataExpedient['court'] || 'N/A'}</td>
                          </tr>

                          <tr>
                            <th className="caratule_heading pR-15">Tipo de juicio:</th>
                            <td className="caratule_content pL-15">{dataExpedient['judicial_party'] || 'N/A'}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br />
                  <div>
                    <h5
                      style={{ fontWeight: 'bold' }}
                    >
                      {'Actor: ' + dataExpedient['actor']}
                    </h5>
                    <div
                      style={{ border: '0px solid rgba(0,0,0,.0625)', borderRadius: '4px' }}
                      className="table-responsive text-center"
                    >
                      <table className="table table-sm table-bordered">
                        <tbody>
                          <tr>
                            <th className="caratule_heading pR-15">Notificaciones electrónicas:</th>
                            <td className="caratule_content pL-15">{dataExpedient['actor_email'] || 'N/A'}</td>
                          </tr>

                          <tr>
                            <th className="caratule_heading pR-15">Tipo de actor:</th>
                            <td className="caratule_content pL-15">{dataExpedient['defendant'] || 'N/A'}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Container>
              </Card.Body>
            </Card>
          </Row>

          <div className="optionsExpedientDocuments">
            <h4 className="w-100 text-center mB-30">Documentos del Expediente</h4>
            
            <div className="clearFloat" />
            <div className="containerSearcherDocuments">
              <ExpedientsSearcher
                placeholderSearch="Escribe el nombre del documento"
                placeholderSearchButton="Buscar documento"
                placeholderCleanButton="Limpiar"
                helperContextual="Escribe el nombre del documento y haz clic en el botón Buscar documento."
                executeSearch={searchDocuments}
                cleanSearch={cleanSearchDocuments}
                isSearching={searching}
                isCleaning={cleaning}
                setSearch={setSearch}
                search={search}
                hasRulesToSearch={false}
              />
            </div>
          </div>

          <Grid
            fluid={true}
            style={{ minHeight: '76vh', width: "65%", margin: "0px auto" }}
          >
            <section className="procedures-table-container mB-50">
              <RemotePagination
                data={dataDocuments}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalDocuments}
                onTableChange={handleTableChange}
                columns={columns}
              />

            </section>
          </Grid>

          <h4 className={dataDocuments.length === 0 && textNotDataDocuments !== "" ? "text-center clearFloat" : "d-n"}>{textNotDataDocuments}</h4>
        </Container>
      </Container>
    </OneColumn>
  );
};

export default ExpedientDetails;