import React from "react";
import { Row } from "react-flexbox-grid";

const ResizingFields = props => {
  function resizingFields() {
    const data = props.dataForm.map((el, index) => {
      return (
        <React.Fragment key={index}>
          {props.createInputBaseTypeForm(el)}
        </React.Fragment>
      );
    });
    return data;
  }
  return <Row>{resizingFields()}</Row>;
};
export default ResizingFields;

// if (el.width < 12) {
//   if (flag) {
//   } else {
//     ColumnsWidth = el.width;
//     return <Row key={index}>{props.createInputBaseTypeForm(el)}</Row>;
//   }
//   // ColumnsWidth = el.width;
//   // return <Row key={index}>{props.createInputBaseTypeForm(el)}</Row>;
// }
// console.log(el.width);
// if (flag === false) {
//   ColumnsWidth += el.width;
//   flag = true;
//   return <Row key={index}>{props.createInputBaseTypeForm(el)}</Row>;
// } else {
//   if (ColumnsWidth + el.width <= 12) {
//     ColumnsWidth = el.width;
//     return props.createInputBaseTypeForm(el);
//   } else {
//     ColumnsWidth = 0;
//     return <Row key={index}>{props.createInputBaseTypeForm(el)}</Row>;
//   }
// }
