//APP.JS
/***********************************IMPORT LIBRARIES*************************************************** */
import React, { useState } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import Cookies from "js-cookie";
import { NotificationContainer } from "react-notifications";

/*************************************ROUTE COMPONENTS****************************************** */
import Login from "./components/login/Login";
import Home from "./components/home/Home";
import Register from "./components/register/Register";
import ForgotPassword from "./components/forgot_password/ForgotPassword";
import NotFound from "./components/error_page/404";
import ChangePassword from "./components/change_password/ChangePassword";
import FinishRegistration from "./components/confirm_registration/FinishRegistration";
import RecoveryPassword from "./components/forgot_password/RecoveryPassword";
import EditProfile from "./components/edit_profile/EditProfile";
import BoostrapTable from "./components/helpers_components/BootstrapTable";
import Formalities from "./components/formalities/Formalities";
import Procedure from "./components/formalities/Procedure";
import RequestOffline from "./components/request_offline/RequestOffline";
import NotificationOffline from "./components/request_offline/NotificationOffline";
import ProcessList from "./components/formalities/ProcessList";
import AlertNotificationTable from "./components/alert_notification_table/AlertNotificationTable";
import RenderForms from "./components/formalities/star_procedure/RenderForms";
import AvatarConfig from "./components/avatar_config/AvatarConfig";
import ConfirmationEmail from "./components/confirmation_email/ConfirmationEmail";
import ErrorPage from "./components/helpers_components/ErrorPage";
import NotificationListContext from "./components/alert_notification_table/notification_context";
import ProcedureList from "./components/procedure_list/ProcedureList";
import LoaderContext from "./components/layout/shared/loader_context";
import ErrorCatcher from "./components/error_page/500";
import SearchLawyers from "./components/search_for_lawyers/SearchLawyers";
import PublicMovements from "./components/search_to_citizens/PublicMovements";
import ExpedientDetails from "./components/governement_book/expedient_details/ExpedientDetails";
import ExpedientsList from "./components/governement_book/my_expedients/ExpedientsList";

const App = props => {
  const isAuth = Cookies.get();

  const [countNotifications, setCountNotifications] = useState(0);
  const [needUpdateCount, setNeedUpdateCount] = useState(false);
  const [statusLoader, setLoaderStatus] = useState(false);

  const updateCountHandler = (state, sumOrSubtract, newCountAlerts) => {
    //THIS FUNCTION ALLOWS CHANGE THE STATE WHEN THE CHECKBOX MARK AS READ AND RENDER THE COMPONENT FOR THE COUNTER ALERT UPDATE
    if( sumOrSubtract === "subtract" ){
      setCountNotifications(countNotifications - 1);
    }
    else if( sumOrSubtract === "sum" ){
      setCountNotifications(newCountAlerts);
    }
    setNeedUpdateCount(state);
  };


  return (
    <ErrorCatcher>
      <BrowserRouter>
        <NotificationListContext.Provider
          value={{
            count: countNotifications,
            updateCount: updateCountHandler,
            requireUpdate: needUpdateCount
          }}
        >
          <LoaderContext.Provider
            value={{
              status: statusLoader,
              show: status => {
                setLoaderStatus(status);
              }
            }}
          >
            <NotificationContainer />
            <LastLocationProvider>
              <Switch>
                <Route
                  exact
                  path="/confirmation_email:token?"
                  component={ConfirmationEmail}
                />
                <Route
                  exact
                  path="/request_offline"
                  component={RequestOffline}
                />
                <Route
                  exact
                  path="/"
                  render={() =>
                    isAuth.isAuth_02 ? (
                      <Home {...props} />
                    ) : (
                      <Login {...props} />
                    )
                  }
                />
                <Route 
                  path="/home" 
                  render={ () => 
                    isAuth.isAuth_02 ? (
                      <Home {...props} />
                    ) : (
                      <Redirect to={"/"} />
                    )
                  } 
                />
                <Route path="/products" component={BoostrapTable} />

                <Route
                  path="/procedure-list"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <ProcedureList history={props.history} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/change-password"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <ChangePassword history={props.history} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/edit-profile"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <EditProfile history={props.history} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/alert-notifications"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <AlertNotificationTable history={props.history} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/formalities"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <Formalities history={props.history} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/render_form"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <RenderForms history={props.history} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  path="/error_page"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <ErrorPage {...props} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  path="/getProcess"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <ProcessList history={props.history} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route
                  render={props =>
                    props.location.state ? (
                      <NotificationOffline location={props.location} />
                    ) : (
                      <NotFound />
                    )
                  }
                  path="/notification_offline"
                />
                <Route
                  path="/recover_password:reset_password_token?"
                  component={RecoveryPassword}
                />
                <Route path="/register" component={Register} />
                <Route
                  path="/finish_registration:token?:reg?"
                  component={FinishRegistration}
                />

                <Route
                  path="/procedure/:id"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <Procedure props={props} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  path="/expedient_details/:expedient"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <ExpedientDetails {...props} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  path="/my-expedients"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <ExpedientsList {...props} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  path="/avatar_config"
                  render={props =>
                    isAuth.isAuth_02 ? (
                      <AvatarConfig history={props.history} />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  exact
                  path="/expedientes/buscar"
                  render={ props => <SearchLawyers {...props} /> }
                /> 
                <Route
                  exact
                  path="/boletin_judicial/consultar"
                  render={ props => <PublicMovements {...props} /> }
                />  
                <Route component={NotFound} />
              </Switch>
            </LastLocationProvider>
          </LoaderContext.Provider>
        </NotificationListContext.Provider>
      </BrowserRouter>
    </ErrorCatcher>
  );
};

export default App;