import React from "react";
import { pdfjs } from "react-pdf";
import { Col } from "react-flexbox-grid";
import DigitalSignatureModal from "../digital_signature/digitalSignatureModal";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DynamicPDF = props => {
  let signatureModal;
  if (props.url) {
  } else {
    signatureModal = props.el.signature ? (
      <Col className="p-5" xs={12}>
        <DigitalSignatureModal {...props} />
      </Col>
    ) : (
      ""
    );
  }

  //const [numPages, setNumPages] = useState(null);
  //const [pageNumber, setPageNumber] = useState(1);

  /* const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  }; */

  return (
    <Col xs={12} className={props.el.visibility["status"] ? "" : " d-none"}>
      {signatureModal}

      <a
        type="button"
        className="btn btn-secondary btn-lg"
        href={`${props.url}?token=${process.env.REACT_APP_TOKEN}`}
        download
      >
        Descargar Archivo
      </a>
      {/* <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${props.url}?token=${process.env.REACT_APP_TOKEN}`}
        >

        </a>
      </div>
      <nav>
        <button onClick={goToPrevPage}>Prev</button>
        <button onClick={goToNextPage}>Next</button>
      </nav>
      <div>
        <a
          type="button"
          className="btn btn-secondary btn-lg"
          href={props.url}
          download
        >
          Descargar Archivo
        </a>
      </div>

      <div>
        <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={800} />
        </Document>
      </div>

      <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </Col>
  );
};

export default DynamicPDF;

/* export default class DynamicPDF extends Component {
  state = { numPages: null, pageNumber: 1 };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>


    this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <div>
        <nav>
          <button onClick={this.goToPrevPage}>Prev</button>
          <button onClick={this.goToNextPage}>Next</button>
        </nav>

        <div style={{ width: 600 }}>
          <Document
            file={process.env.PUBLIC_URL + "/CV_Dominguez Gomez Adrian.pdf"}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} width={600} />
          </Document>
        </div>

        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div>
    );
  }
}*/
