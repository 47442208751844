import React from "react";
import { NotificationManager } from "react-notifications";
import { NewClassRequest } from "../classes/NewClassRequest";

export const searchExpedientsToLawyer = async (
    court_id,
    expedient_number,
    setResult,
    setMessageNoResult
) => {
  expedient_number = expedient_number.toUpperCase();

  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/find/number/${court_id}/${expedient_number}`,
    "post",
    null,
    {}
  );
  const responseExpedientSearch = await request.executeRequest();

  if ( responseExpedientSearch.code !== 200 ) {

    if (
        responseExpedientSearch.response.message && 
        typeof responseExpedientSearch.response.message === "string" && 
        responseExpedientSearch.response.message !== "" 
    ) {
        setMessageNoResult( responseExpedientSearch.response.message );
    }
  } 
  else {
      if (
        responseExpedientSearch.response.error &&
        responseExpedientSearch.response.message && 
        typeof responseExpedientSearch.response.message === "string" && 
        responseExpedientSearch.response.message !== "" 
      ) {

        setMessageNoResult( responseExpedientSearch.response.message );
        NotificationManager.error( responseExpedientSearch.response.message )
      }
      else {  

        setResult( userOrDependenceResponsable(responseExpedientSearch.response) );
      }
  }
};

export const getJudicialParties = async (
  setJudicialParties,
  setIsLoading
) => {
  setIsLoading(true);
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/judicial_parties/get_list`,
    "get",
    null,
    {}
  );

  const responseJudicialParties = await request.executeRequest();
  setIsLoading(false);

  if ( responseJudicialParties.code === 200 && 
    responseJudicialParties.response.judicialParties && 
    responseJudicialParties.response.judicialParties.length > 0 ) {

      setJudicialParties(responseJudicialParties.response.judicialParties);
  }
  else if ( responseJudicialParties.code === 200 && 
    responseJudicialParties.response.judicialParties && 
    responseJudicialParties.response.judicialParties.length === 0 ) {

      setJudicialParties([{ label:"No existe ningún partido judicial", value:null }]);
  }
  else {

    NotificationManager.error("Ha ocurrido un error a la hora de traer los partidos judiciales, inténtalo nuevamente");
  }
};


export const getCourtsByJudicialPartyId = async (
  judicial_party_id,
  setCourts
) => {

  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/courts/get_list/${judicial_party_id}`,
    "get",
    null,
    {}
  );

  const responseCourts = await request.executeRequest();

  if ( responseCourts.code === 200 && 
    responseCourts.response.courts && 
    responseCourts.response.courts.length > 0 ) {

      setCourts(responseCourts.response.courts);
  }
  else if ( responseCourts.code === 200 && 
    responseCourts.response.courts && 
    responseCourts.response.courts.length === 0 ) {

      setCourts([{ label:"No existe ningún juzgado en el partido judicial seleccionado", value:null }]);
  }
  else {

    NotificationManager.error("Ha ocurrido un error a la hora de traer los juzgados, inténtalo nuevamente");
  }
};


export const fieldsValidation = ( requiredFields, objectToSend ) => {

  const fieldsObjectToSend = Object.keys(objectToSend);
  let missingFields = {};

  requiredFields.map( (field,index) => {

    if ( !fieldsObjectToSend.includes(field) || !objectToSend[field] ) {

      missingFields[field] = ["Este campo es requerido"];
    };
  });

  if ( Object.keys(missingFields).length > 0 ) {
      
    return missingFields;
  }
  else return true;

};

export const renderErrorsByInputName = (errors, inputName) => {

  if ( errors && errors[inputName] ) {
    const errorsList = errors[inputName].map((error, index) => {

      return (
        <li key={index} style={{padding:"0px 25px 0px", color:"red", fontSize:".8rem"}}>
          <small>
            {error}
          </small>
        </li>
      );
    })
    return errorsList;
  }
};


export const userOrDependenceResponsable = (responseExpedientSearch) => {
  let resultToShow = {};
  if (
    responseExpedientSearch['electronicExpedient']['external'] &&
    responseExpedientSearch['electronicExpedient']['dependence'] && 
    typeof responseExpedientSearch['electronicExpedient']['dependence'] === 'object'
  ) {
    resultToShow['external'] = true;
    resultToShow['expedient_number'] = responseExpedientSearch.electronicExpedient.expedient_number;
    resultToShow['dependence'] = responseExpedientSearch.electronicExpedient.dependence.name;
    resultToShow['street'] = responseExpedientSearch.electronicExpedient.dependence.street;
    resultToShow['external_number'] = responseExpedientSearch.electronicExpedient.dependence.external_number;
    resultToShow['colony'] = responseExpedientSearch.electronicExpedient.dependence.colony;
    resultToShow['municipality'] = responseExpedientSearch.electronicExpedient.dependence.municipality;
    resultToShow['state'] = responseExpedientSearch.electronicExpedient.dependence.state;
    resultToShow['zip_code'] = responseExpedientSearch.electronicExpedient.dependence.zip_code;
    resultToShow['group'] = responseExpedientSearch.user.group.toUpperCase();
    resultToShow['court'] = responseExpedientSearch.electronicExpedient.court;
    resultToShow['judicial_party'] = responseExpedientSearch.electronicExpedient.judicial_party;  
    resultToShow['date'] = new Date( responseExpedientSearch.electronicExpedient.turn_date.date.replace(/-/g, "/") ).toLocaleDateString('es-MX', {
            day:'numeric',
            month:'long',
            year:'numeric'
          })  
  }
  else {
    resultToShow['expedient_number'] = responseExpedientSearch.electronicExpedient.expedient_number;
    resultToShow['user'] = responseExpedientSearch.user.name;
    resultToShow['group'] = responseExpedientSearch.user.group.toUpperCase();
    resultToShow['court'] = responseExpedientSearch.electronicExpedient.court;
    resultToShow['judicial_party'] = responseExpedientSearch.electronicExpedient.judicial_party;  
    resultToShow['date'] = new Date( responseExpedientSearch.electronicExpedient.updated_at.replace(/-/g, "/") ).toLocaleDateString('es-MX', {
            day:'numeric',
            month:'long',
            year:'numeric'
          })   
  } 
  
  return resultToShow;
};