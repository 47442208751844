import React from "react";
import { Dropdown, Col, Modal, Row, Card, Button } from "react-bootstrap";
import { Request } from "../classes/Request";
import Cookies from "js-cookie";

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getListOfTask = async (props, setTaskDetails) => {
  const { userId_02 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/executed_stages/${props.el.procedure_id}/${userId_02}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );

  const getListOfTaskData = await request.executeRequest();
  if (getListOfTaskData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE

    const taskList = getListOfTaskData.data.data.data.stages.map(
      (el, index) => {
        return (
          <Dropdown.Item
            key={index}
            onClick={()=>getStageDetail(el.id, props.setShowModal, props.setModalElements)}
          >
            {el.name}
          </Dropdown.Item>
        );
      }
    );
    setTaskDetails(taskList);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE

    setTaskDetails(
      <Dropdown.Item href="#/action-1">
        No se encontraron pasos para esta etapa
      </Dropdown.Item>
    );
  }
};

export const getStageDetail = async (idStage, setShowModal, setModalElements) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/stage/detail/${idStage}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getStageDetailData = await request.executeRequest();
  if (getStageDetailData.code === 200) {
    setModalElements(
      <>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Seguimiento Etapa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
          <Row className="pB-40">{makeFormsBasedResponse(getStageDetailData.data.data.data.stage.forms)}</Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>setShowModal(false)}>Cerrar</Button>
        </Modal.Footer>
     </>
    );
    setShowModal(true);
  }
};

const makeFormBasedFields = fields => {
  const formFields = fields.map((el, index) => {
    if (typeof el.value == "object") {
      return (
        <tr key={index}>
          <td
            className="font-weight-bold text-right"
            style={{ width: "40%" }}
          >
            {el.label}:
          </td>
          <td className="break-word text-justify">
            {el.value.map((option, indexOption) => {
              let options = "";

              // add options selected
              for (let key in option) {
                options = option[key] ? key + ", " : "";
              }

              return options;
            })}
          </td>
        </tr>
      );
    } else if (el.value.includes("http")) {
      return (
        <tr key={index}>
          <td
            className="font-weight-bold text-right"
            style={{ width: "40%" }}
          >
            {el.label}:
          </td>
          <td className="break-word text-justify">
            <a href={el.value} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
              <i className="ti-download mr-2" title="Descargar" />
              Descargar
            </a>
          </td>
        </tr>
      );
    }

    return (
      <tr key={index}>
        <td className="font-weight-bold text-right" style={{ width: "40%" }}>
          {el.label}:
        </td>
        <td className="break-word text-justify">{el.value}</td>
      </tr>
    );
  });
  return formFields;
};

const makeFormsBasedResponse = form => {
  if (form.length > 0) {
    const responseFormFormatted = form.map((el, index) => {
      return (
        <Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 0 }}>
          <Card className="mB-20">
            <Card.Body>
              <div className="table-responsive">
                <table className="nilo w-100">
                  <thead>
                    <tr>
                      <th colSpan={2} className="text-center p-20">
                        <h4 className="mb-0">{el.name}</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{makeFormBasedFields(el.fields)}</tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    });
    return responseFormFormatted;
  } else {
    return (
      <div className="alert alert-warning" role="alert">
        La etapa no se encuentra vinculada a ningún trámite, favor de intentarlo
        de nuevo más tarde.
      </div>
    );
  }
};